import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useDebounce } from '@/hooks/useDebounce';
import useLoading from '@/hooks/useLoading';
import { api } from '@/services/api/api';
import { TOriginDetail } from '@/services/api/api.responses';
import { getAxiosErrorMessage } from '@/services/api/api.utils';
import { socket } from '@/services/socket';
import { TOrderBy } from '@/types';

import { DatePickerRange } from '@/components/DateRangePicker';
import DefaultPage from '@/components/DefaultPage';
import LazyLoadComponent from '@/components/LazyLoadComponent';
import OrderBy from '@/components/OrderBy';
import OriginCard from '@/components/OriginCard';
import SearchInput from '@/components/SearchInput';
import Subtitle from '@/components/Subtitle';
import Title from '@/components/Title';
import myToast from '@/components/Toast';
import { Skeleton } from '@/components/ui/skeleton';
import VirtualScroll from '@/components/VirtualScroll';
import { Frown } from 'lucide-react';

export default function Leads() {
  const { state } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams(state?.searchParams);
  const originQueryParam = searchParams.get('busca');
  const orderNameParam = searchParams.get('ordenar');
  const descParam = searchParams.get('desc');
  const startDateParam = searchParams.get('data-inicio');
  const endDateParam = searchParams.get('data-fim');

  const [originQuery, setOriginQuery] = useState<string>(
    originQueryParam || '',
  );
  const [leadsOrigins, setLeadsOrigins] = useState<TOriginDetail[]>([]);
  const [orderBy, setOrderBy] = useState<TOrderBy[]>(() => {
    if (orderNameParam && descParam) {
      const order = descParam == 'true' ? 'desc' : 'asc';

      if (orderNameParam == 'Data') {
        return [
          { selected: false, name: 'Nome', order: 'asc' },
          { selected: true, name: 'Data', order },
        ];
      }
      if (orderNameParam == 'Nome') {
        return [
          { selected: true, name: 'Nome', order },
          { selected: false, name: 'Data', order: 'desc' },
        ];
      }
    }
    return [
      { selected: false, name: 'Nome', order: 'asc' },
      { selected: true, name: 'Data', order: 'desc' },
    ];
  });

  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >(() => {
    return {
      from: startDateParam ? dayjs(startDateParam).toDate() : undefined,
      to: endDateParam ? dayjs(endDateParam).toDate() : undefined,
    };
  });

  const [isLoadingOriginLeads, startLoadOriginLeads] = useLoading(true);
  const debouncedOriginQuery = useDebounce(originQuery);

  const isLoadedFirstTime = useRef<boolean>(false);

  function handleChangeOrderBy(orderBy: TOrderBy[]) {
    setOrderBy(orderBy);
    const selectedOrderBy = orderBy.find((order) => order.selected);

    if (selectedOrderBy?.name && selectedOrderBy.order) {
      searchParams.set('ordenar', selectedOrderBy.name);
      searchParams.set('desc', String(selectedOrderBy.order == 'desc'));
    } else {
      searchParams.delete('ordenar');
    }

    setSearchParams(searchParams);
  }

  async function getAllLeadsOrigins(options?: {
    query?: string;
    notStartLoad?: boolean;
    orderBy?: TOrderBy;
    dateRange?: DateRange;
  }) {
    async function getOriginLeads() {
      const start_date = selectedDateRange?.from?.apiDateFormat();
      const end_date = selectedDateRange?.to?.apiDateFormat();

      const queryParam = searchParams.get('busca');

      const orderNameParam = searchParams.get('ordenar');
      const descParam = searchParams.get('desc');
      const orderParam = descParam
        ? descParam == 'true'
          ? 'desc'
          : 'asc'
        : 'desc';

      const orderBy = (orderNameParam || 'data') + '-' + orderParam;

      await api
        .get<TOriginDetail[]>('/leads/origens', {
          params: {
            pesquisa: options?.query ?? queryParam,
            ordenar: orderBy.toLowerCase(),
            ...(end_date && start_date
              ? { primeiro_registro: start_date }
              : ''),
            ...(start_date && end_date ? { ultimo_registro: end_date } : ''),
          },
        })
        .then(({ data }) => {
          setLeadsOrigins(data);
        })
        .catch((error) => {
          setLeadsOrigins([]);
          if (isAxiosError(error)) {
            if (error.response?.status == 404) {
              return;
            }
          }

          myToast.error(
            getAxiosErrorMessage(
              error,
              'Não foi possível obter os lead, tente novamente mais tarde.',
            ),
          );
        });
    }

    if (options?.notStartLoad) {
      getOriginLeads();
    } else {
      startLoadOriginLeads(() => getOriginLeads());
    }
  }

  function handleSelectDateRange(date?: DateRange) {
    if (date?.from) {
      searchParams.set('data-inicio', dayjs(date?.from).format('YYYY-MM-DD'));
    } else {
      searchParams.delete('data-inicio');
    }

    if (date?.to) {
      searchParams.set('data-fim', dayjs(date?.to).format('YYYY-MM-DD'));
    } else {
      searchParams.delete('data-fim');
    }

    setSearchParams(searchParams);
    setSelectedDateRange(date);
  }

  async function handleDeleteOrigin(originId: number) {
    await api
      .delete('leads/remover', {
        params: { crm_origem_id: originId },
      })
      .then(() => {
        setLeadsOrigins((prev) =>
          prev.filter((leads) => leads.crm_origem_id != originId),
        );
        const foundOrigin = leadsOrigins.find(
          (origin) => origin.crm_origem_id == originId,
        );

        const originName = foundOrigin?.crm_origem_nome;

        if (originName) {
          myToast.info(`Leads da origem *${originName}* excluídos com sucesso`);
          return;
        }
        myToast.info('Leads excluídos com sucesso');
      })
      .catch((error) =>
        myToast.error(
          getAxiosErrorMessage(error, 'Não foi possível excluir os leads'),
        ),
      );
  }

  useEffect(() => {
    if (isLoadedFirstTime.current) {
      setLeadsOrigins([]);
      getAllLeadsOrigins();
    }
  }, [startDateParam, endDateParam, orderNameParam, descParam]);

  useEffect(() => {
    if (isLoadedFirstTime.current) {
      if (debouncedOriginQuery) {
        searchParams.set('busca', debouncedOriginQuery ?? '');
      } else {
        searchParams.delete('busca');
      }
      getAllLeadsOrigins({ query: debouncedOriginQuery ?? '' });

      setSearchParams(searchParams);
      return;
    }
  }, [debouncedOriginQuery]);

  useEffect(() => {
    getAllLeadsOrigins().finally(() => (isLoadedFirstTime.current = true));

    function handleNewOrigin() {
      getAllLeadsOrigins({ notStartLoad: true });
    }

    socket.on('leads_origens_infos', handleNewOrigin);

    return () => {
      socket.off('leads_origens_infos', handleNewOrigin);
    };
  }, []);

  return (
    <DefaultPage
      scrollToTopButton
      className="mx-auto max-w-6xl"
      goBackTo={{ to: '/' }}
      pageDescription={{
        title: 'LEADS CADASTRADOS',
        description: (
          <div>
            Gerencie <b>leads</b> cadastrados e segmentados pelo sistema.
          </div>
        ),
      }}
    >
      <header>
        <Title marginBottom className="text-center">
          Leads cadastrados
        </Title>
        <Subtitle className="mb-16 text-center text-foreground/60">
          Leads cadastrados, separados por origem
        </Subtitle>
      </header>

      <div className="w-full">
        <div>
          <SearchInput
            autoFocus
            value={originQuery}
            maxLength={100}
            onChangeValue={setOriginQuery}
            placeholder="Buscar por origem ..."
          />

          <div className="mt-12 flex w-full flex-wrap justify-center gap-5 sm:justify-between">
            <OrderBy
              disabled={isLoadingOriginLeads}
              items={orderBy}
              onChange={handleChangeOrderBy}
            />

            <DatePickerRange
              currentMounthButton
              date={selectedDateRange}
              onSelect={handleSelectDateRange}
              disabled={isLoadingOriginLeads}
            />
          </div>
        </div>

        <Skeleton
          data-visible={!isLoadingOriginLeads && !leadsOrigins.length}
          className="base-text mt-20 flex h-64 w-full select-none items-center justify-center gap-2 text-muted-foreground data-[visible=false]:hidden"
        >
          {!debouncedOriginQuery ? (
            <p>Nenhum lead cadastrado</p>
          ) : (
            <>
              <Frown size={35} /> <p>Nenhum resultado encontrado</p>
            </>
          )}
        </Skeleton>

        <div
          data-visible={isLoadingOriginLeads}
          className="mt-20 grid gap-20 data-[visible=false]:hidden"
        >
          <Skeleton className="h-64 w-full" />
          <Skeleton className="h-64 w-full" />
        </div>

        <VirtualScroll canLoadMore={false} loadMore={() => {}}>
          {leadsOrigins ? (
            <section
              data-hidden={isLoadingOriginLeads}
              className="mt-20 grid gap-24 data-[hidden=true]:hidden"
            >
              {leadsOrigins.length > 5
                ? leadsOrigins.map((origin) => (
                    <LazyLoadComponent
                      key={origin.crm_origem_id}
                      observerMargin="0px"
                    >
                      <OriginCard
                        origin={origin}
                        search={debouncedOriginQuery ?? ''}
                        onDeleteOrigin={handleDeleteOrigin}
                      />
                    </LazyLoadComponent>
                  ))
                : leadsOrigins.map((origin) => (
                    <OriginCard
                      key={origin.crm_origem_id}
                      origin={origin}
                      search={debouncedOriginQuery ?? ''}
                      onDeleteOrigin={handleDeleteOrigin}
                    />
                  ))}
            </section>
          ) : null}
        </VirtualScroll>
      </div>
    </DefaultPage>
  );
}
