import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '@/contexts/authContext';
import useLoading from '@/hooks/useLoading';
import { api } from '@/services/api/api';
import { TOriginDetail } from '@/services/api/api.responses';
import { socket } from '@/services/socket';

import Background from '@/components/Background';
import Counter from '@/components/Counter';
import DefaultPage from '@/components/DefaultPage';
import { EventsCarousel } from '@/components/EventsCarousel';
import Title from '@/components/Title';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { PartyPopper, UserSearch } from 'lucide-react';

export default function Home() {
  const { profile } = useAuth();
  const [totalLeads, setTotalLeads] = useState<number>(0);

  const [isLoadingOriginsLeads, startLoadOriginsLeads] = useLoading(true);

  function getTotalLeads(leadsGroup: TOriginDetail[]) {
    return leadsGroup?.reduce((acc, current) => acc + current.total_leads, 0);
  }

  useEffect(() => {
    setTimeout(() => {
      scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    api.get<TOriginDetail[]>('leads/origens').then(({ data }) => {
      startLoadOriginsLeads(async () => setTotalLeads(getTotalLeads(data)));
    });

    socket.on('leads_origens_infos', (data: TOriginDetail[]) => {
      setTotalLeads(getTotalLeads(data));
    });

    return () => {
      socket.off('leads_origens_infos');
    };
  }, []);

  return (
    <DefaultPage>
      <Background />
      <main className="w-full max-w-6xl p-5 backdrop-blur-sm">
        <Title className="animate-fade-in rounded-md opacity-0">
          <div>
            <p className="medium-text mb-1 ml-1 opacity-80">
              Seja bem-vindo(a) ao{' '}
              <span className="text-pormade underline">AutoLeads</span>,
            </p>
            {profile?.user ? (
              <span className="capitalize">
                {' '}
                {profile?.user.toLocaleLowerCase() ?? ''}
              </span>
            ) : null}
          </div>
        </Title>
        <div className="mt-10 flex flex-col items-center">
          <div className="mt-2 flex size-fit animate-fade-in flex-col items-center justify-center opacity-0 delay-700">
            <Counter
              value={totalLeads}
              isLoading={isLoadingOriginsLeads}
              description="Leads Cadastrados"
            />

            <Link
              to={'/leads'}
              className="animate-fade-in opacity-0 delay-1000"
            >
              <Button variant={'outline'} className="flex gap-2">
                <UserSearch /> Ver Leads
              </Button>
            </Link>
          </div>
          <Separator className="my-16 animate-fade-in bg-soft-gray opacity-0 delay-1000" />
          <EventsCarousel monthly />
          <Link
            to={'/eventos'}
            className="mt-10 animate-fade-in opacity-0 delay-1000"
          >
            <Button variant={'outline'} className="flex gap-2">
              <PartyPopper /> Ver Eventos
            </Button>
          </Link>
        </div>
      </main>
    </DefaultPage>
  );
}
