import './index.css';

import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routerChildrens } from './routes';

import App from './App';
import AuthenticatedRoute from './pages/AuthenticatedRoute';
import RouteErrorPage from './pages/error/RouteErrorPage';
import { setInitialTheme } from './utils/theme.utils';

setInitialTheme();

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: '/',
        element: <AuthenticatedRoute />,
        children: routerChildrens,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <RouterProvider router={router} />,
  // </React.StrictMode>
);
