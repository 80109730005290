import { cn } from '@/lib/utils';
import { ChevronDown } from 'lucide-react';
import { ReactNode, useReducer } from 'react';
import { Separator } from './ui/separator';

type FrameProps = {
  className?: string;
  children: ReactNode;
  moreContent?: ReactNode | string;
  moreContentText?: {
    opened: string;
    closed: string;
  };
};
export default function Frame({
  children,
  className,
  moreContent,
  moreContentText,
}: FrameProps) {
  const [isOpenMoreContent, toogleMoreContent] = useReducer((state) => {
    return !state;
  }, false);

  return (
    <div
      className={cn(
        'relative w-full rounded-2xl rounded-tl-none border border-muted-foreground px-7 py-5 shadow-md',
        className,
      )}
    >
      {children}
      {moreContent ? (
        <div>
          <Separator className="mb-2 mt-5 w-full" />

          <div
            className={`w-full overflow-hidden transition-all duration-500 ease-in-out ${
              !isOpenMoreContent ? 'max-h-0 py-0' : 'max-h-screen'
            }`}
          >
            {moreContent}
          </div>
          <button
            title={
              moreContentText
                ? isOpenMoreContent
                  ? moreContentText.opened
                  : moreContentText.closed
                : `Ver ${isOpenMoreContent ? 'menos' : 'mais'} informações`
            }
            className="mx-auto mt-5 w-full"
            onClick={toogleMoreContent}
          >
            <div
              className={cn(
                'flex flex-col items-center justify-center pt-4 text-muted-foreground transition-colors duration-200 hover:text-foreground',
                isOpenMoreContent ? 'flex-col-reverse' : '',
              )}
            >
              <p className="medium-text">
                {moreContentText
                  ? isOpenMoreContent
                    ? moreContentText.opened
                    : moreContentText.closed
                  : `Ver ${isOpenMoreContent ? 'menos' : 'mais'}`}
              </p>
              <ChevronDown
                className={cn(isOpenMoreContent ? '-scale-y-90' : '')}
              />
            </div>
          </button>
        </div>
      ) : null}
    </div>
  );
}
