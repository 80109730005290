import { CalendarCheck2, CalendarClock, Hourglass } from 'lucide-react';

const colors = {
  sucess: 'pormade',
  info: 'pormade-blue',
  warn: 'pormade-warn',
  error: 'pormade-red',
};

export const eventStages = {
  AGENDADO: {
    name: 'Agendado',
    color: colors.warn,
    icon: CalendarClock,
    description: 'Esse evento foi agendado.',
  },
  PROGRESSO: {
    name: 'Em progresso',
    color: colors.info,
    icon: Hourglass,
    description: 'Esse evento está em progresso.',
  },
  CONCLUÍDO: {
    name: 'Concluído',
    color: colors.sucess,
    icon: CalendarCheck2,
    description: 'Esse evento foi concluído',
  },
};
