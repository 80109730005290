import { dddsNumbers } from '@/constants/ddd';
import { cellPhoneRegex } from '@/utils/regex.utils';
import { z } from 'zod';

const messages = {
  requiredField: 'Campo obrigatório.',
  requiredContact: 'Insira ao menos uma opção de contato.',
  invalidCellPhone: 'Insira um celular válido.',
  invalidPhone: 'Insira um telefone válido.',
  invalidDDD: 'Insira um DDD valido.',
  invalidEmail: 'Insira um email válido.',
};

function validateDDDPhoneNumber(value: string) {
  return dddsNumbers.includes(value.removeSpecialCharacters().substring(0, 2));
}

export const registerLeadSchema = z.object({
  nome: z
    .string()
    .min(1, messages.requiredField)
    .min(3, 'O nome deve ter no mínimo 3 caractéres.')
    .transform((value) => value.limitSpaces()),
  profissao: z
    .string({ required_error: messages.requiredField })
    .min(1, messages.requiredField)
    .transform((value) => value.limitSpaces()),
  estado_id: z
    .string({ required_error: messages.requiredField })
    .min(1, messages.requiredField),
  ibge: z
    .string({ required_error: messages.requiredField })
    .min(1, messages.requiredField),
  //celular
  celular:
    // z.literal('').or(
    z
      .string()
      .min(1, messages.requiredField)
      .trim()
      .regex(cellPhoneRegex, messages.invalidCellPhone)
      .refine((value) => validateDDDPhoneNumber(value), messages.invalidDDD),
  // ),
  // telefone: z.literal('').or(
  //   z
  //     .string()
  //     .trim()
  //     .regex(phoneRegex, messages.invalidPhone)
  //     .refine(value => validateDDDPhoneNumber(value), messages.invalidDDD)
  // ),
  email: z
    .string()
    .trim()
    .email(messages.invalidEmail)
    .or(z.string().max(0))
    .transform((value) => {
      if (!value) return null;
      return value;
    }),
  empresa: z
    .string()
    .trim()
    .transform((value) => value?.limitSpaces())
    .transform((value) => {
      if (!value) return null;
      return value;
    }),
  observacoes: z
    .string()
    .trim()
    .transform((value) => value?.limitSpaces())
    .transform((value) => {
      if (!value) return null;
      return value;
    }),
});
// .superRefine((value, ctx) => {
//   if (
//     !value.telefone?.trim()
//      && !value.celular.trim()
//   ) {
//     [
//       'telefone',
//       'celular'
//     ].map(field =>
//       ctx.addIssue({
//         code: ZodIssueCode.custom,
//         message: messages.requiredContact,
//         path: [field],
//       })
//     );
//   }
//   return ctx;
// });

export type TRegisterLeadSchema = z.infer<typeof registerLeadSchema>;
