import { defaultInputClassName } from '@/configs/input.config';
import { cn } from '@/lib/utils';
import { Eye, EyeOff } from 'lucide-react';
import { InputHTMLAttributes, ReactNode, forwardRef, useReducer } from 'react';
import InputLabel from '../InputLabel';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  errorMessage?: string;
  onChangeValue?: (value: string) => void;
  optional?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      label,
      className,
      errorMessage,
      onChangeValue,
      optional,
      disabled,
      ...props
    },
    ref,
  ) => {
    const inputId = props.id ?? `${Math.random() * 100}-${label}`;

    const [isHiddenPassword, toggleIsHiddenPassword] = useReducer(
      (state) => !state,
      true,
    );

    return (
      <div className="w-full">
        {label ? (
          <InputLabel
            htmlFor={inputId}
            required={props.required}
            optional={optional}
          >
            {label}
          </InputLabel>
        ) : (
          ''
        )}

        <div className="relative">
          <input
            id={inputId}
            type={
              type != 'password'
                ? type
                : !isHiddenPassword && !disabled
                  ? 'text'
                  : type
            }
            className={cn(
              defaultInputClassName(errorMessage),
              type == 'password' && 'pr-10',
              label && 'mt-1',
              className,
            )}
            ref={ref}
            {...(onChangeValue
              ? { onChange: (e) => onChangeValue(e.target.value) }
              : null)}
            disabled={disabled}
            {...props}
          />
          {type == 'password' ? (
            <button
              type="button"
              className="absolute bottom-0 right-3 top-0 my-2 flex items-center justify-center text-dark-gray dark:text-soft-gray"
              onClick={toggleIsHiddenPassword}
              disabled={disabled}
            >
              {isHiddenPassword || disabled ? (
                <EyeOff className="p-[0.1rem] opacity-80" />
              ) : (
                <Eye className="p-[0.1rem]" />
              )}
            </button>
          ) : (
            ''
          )}
        </div>
        <span
          data-hidden={!errorMessage}
          className="text-sm font-medium text-pormade-red data-[hidden=true]:hidden"
        >
          {errorMessage}
        </span>
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
