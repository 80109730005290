import { cn } from '@/lib/utils';
import React from 'react';
import {
  Id,
  ToastContent,
  ToastOptions,
  toast as toastifyToast,
} from 'react-toastify';

type toastMessageProps = {
  text: string;
  strongColor:
    | 'text-pormade-warn'
    | 'text-pormade'
    | 'text-pormade-red'
    | 'text-pormade-blue';
  toastFunction: <TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions<TData> | undefined,
  ) => Id;
  options?: ToastOptions<unknown>;
};

function toastMessage({
  text,
  strongColor,
  toastFunction,
  options,
}: toastMessageProps) {
  if (typeof text == 'string') {
    const parts = text?.split(/\*(.*?)\*/);
    if (!toastifyToast.isActive(text)) {
      return toastFunction(
        <div className="ml-2 py-2">
          {parts?.map((part, index) => (
            <React.Fragment key={index}>
              {index % 2 === 1 ? (
                <strong className={cn('font-semibold', strongColor)}>
                  {part}
                </strong>
              ) : // <strong className="font-semibold text-pormade">{part}</strong>
              part.includes('\\n') ? (
                part.split('\\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {i > 0 && <br />}
                    {line}
                  </React.Fragment>
                ))
              ) : (
                part
              )}
            </React.Fragment>
          ))}
        </div>,
        {
          toastId: text,
          ...(!options?.autoClose ? { autoClose: 6000 } : null),
          ...options,
        },
      );
    }
  }
}

const myToast = {
  success: (message: string, options?: ToastOptions<unknown>) =>
    toastMessage({
      text: message,
      toastFunction: toastifyToast.success,
      strongColor: 'text-pormade',
      options: { ...options },
    }),
  warn: (message: string, options?: ToastOptions<unknown>) =>
    toastMessage({
      text: message,
      toastFunction: toastifyToast.warn,
      strongColor: 'text-pormade-warn',
      options: { ...options },
    }),
  error: (message: string, options?: ToastOptions<unknown>) =>
    toastMessage({
      text: message,
      toastFunction: toastifyToast.error,
      strongColor: 'text-pormade-red',
      options: { ...options },
    }),
  dismiss: (id: string) => toastifyToast.dismiss(id),
  info: (message: string, options?: ToastOptions<unknown>) =>
    toastMessage({
      text: message,
      toastFunction: toastifyToast.info,
      strongColor: 'text-pormade-blue',
      options: { ...options },
    }),
  close: (id: Id) => toastifyToast.dismiss(id),
};

export default myToast;
