export const prefersDarkmode = window.matchMedia(
  '(prefers-color-scheme: dark)',
).matches;

export const storedDarkModeValue = window.localStorage.getItem('darkMode');

export const initialDarkModeEnabled = storedDarkModeValue
  ? storedDarkModeValue === 'true'
  : prefersDarkmode;

export function setInitialTheme() {
  if (storedDarkModeValue) {
    const darkModeIsEnabled = document.body.classList.contains('dark');

    if (initialDarkModeEnabled && !darkModeIsEnabled) {
      document.body.classList.toggle('dark');
      window.localStorage.setItem('darkMode', String(true));
    } else {
      window.localStorage.setItem('darkMode', String(false));
    }
  } else {
    window.localStorage.setItem('darkMode', String(initialDarkModeEnabled));

    if (initialDarkModeEnabled) {
      document.body.classList.toggle('dark');
    }
  }
}
