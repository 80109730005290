import { cn } from '@/lib/utils';
import { ReactNode } from 'react';

type InputLabelProps = {
  required?: boolean;
  optional?: boolean;
  htmlFor?: string;
  children: ReactNode;
  className?: string;
};
export default function InputLabel({
  htmlFor,
  required,
  optional,
  children,
  className,
}: InputLabelProps) {
  return (
    <label
      className={cn(
        required
          ? 'after:ml-1 after:text-pormade-red after:content-["*"]'
          : optional &&
              'after:ml-2 after:text-sm after:text-gray after:content-["(opcional)"]',
        'font-semibold',
        className,
      )}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}
