import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/contexts/authContext';

import Background from '@/components/Background';
import { Separator } from '@/components/ui/separator';
import { Headset, Loader2 } from 'lucide-react';
import { useEffect, useReducer, useRef } from 'react';
import { version } from '../../package.json';
import ToggleThemeButton from '@/components/ToggleThemeButton';

const loginSchema = z.object({
  usuario: z.string().min(1, 'Digite seu usuário.'),
  senha: z.string().min(1, 'Digite sua senha.').trim(),
});

type TLoginSchema = z.infer<typeof loginSchema>;

export default function LoginPage() {
  const {
    handleSubmit,
    register,
    setFocus,
    setError,
    formState: { errors },
  } = useForm<TLoginSchema>({
    resolver: zodResolver(loginSchema),
  });
  const { login } = useAuth();

  const [isLoading, toggleIsLoading] = useReducer((state) => !state, false);

  const loginRef = useRef<HTMLDivElement>(null);

  function Handlelogin(data: TLoginSchema) {
    toggleIsLoading();
    login(data)
      .catch(() => {
        setError('usuario', { message: ' ' });
        setError('senha', { message: ' ' });
        setTimeout(() => {
          setFocus('usuario');
        }, 50);
      })
      .finally(toggleIsLoading);
  }

  useEffect(() => {
    setTimeout(() => {
      loginRef.current?.classList.replace(
        '-translate-y-[130%]',
        'translate-y-0',
      );
    }, 100);
  }, []);

  return (
    <div className="fixed flex size-full items-center justify-center gap-20 md:p-20 xl:gap-40">
      <Background />

      <ToggleThemeButton
        className="absolute right-0 top-0 m-3 p-1 text-foreground"
        size={28}
      />

      <section className="hidden animate-fade-in select-none flex-col items-center justify-center p-2 text-center opacity-0 backdrop-blur-sm delay-500 lg:flex">
        <p>
          <strong className="text-6xl font-black text-pormade">
            AutoLeads
          </strong>
        </p>

        <Separator className="my-4 w-72 bg-foreground" />
        <p className="medium-text">
          Sistema de gerenciamento e segmentação de leads
        </p>
      </section>

      <div
        ref={loginRef}
        className="flex w-full -translate-y-[130%] flex-col gap-16 rounded-md p-12 ring-foreground backdrop-blur-sm transition-transform duration-1000 ease-in-out sm:w-fit sm:p-20 sm:shadow-xl sm:shadow-pormade-hover/10 sm:ring-[0.5px]"
      >
        <section className="mx-auto w-full">
          <div className="mb-10">
            <h1 className="mb-1 text-2xl font-bold md:text-3xl">Login</h1>
            <h2 className="medium-text">
              Efetue o login para acessar o sistema
            </h2>
          </div>

          <form onSubmit={handleSubmit(Handlelogin)}>
            <section className="grid gap-3">
              <Input
                autoFocus
                disabled={isLoading}
                label="Usuário"
                placeholder="Usuário"
                autoComplete="username"
                errorMessage={errors.usuario?.message}
                {...register('usuario')}
              />

              <Input
                disabled={isLoading}
                label="Senha"
                placeholder="Senha"
                type="password"
                autoComplete="current-password"
                errorMessage={errors.senha?.message}
                {...register('senha')}
              />
            </section>
            <div className="mt-14 flex justify-center">
              <Button disabled={isLoading} className="w-full" type="submit">
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 animate-spin" /> Acessando
                  </>
                ) : (
                  'Acessar'
                )}
              </Button>
            </div>

            <a
              href={import.meta.env.VITE_SUPPORT_URL}
              target="_blank"
              className="mx-auto mt-8 flex w-fit items-center gap-2 p-2 text-sm hover:opacity-80"
            >
              <Headset size={20} /> Suporte
            </a>
          </form>
        </section>
      </div>

      <span className="absolute bottom-0 animate-fade-in p-5 text-base text-muted-foreground opacity-0 delay-300 sm:right-0 sm:text-lg">
        Versão: {version}
      </span>
    </div>
  );
}
