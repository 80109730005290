import ErrorPage from '@/components/ErrorPage';
import { AlertTriangle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export default function RouteErrorPage() {
  const error = useRouteError();

  const [errorStatus, setErrorStatus] = useState<number>();

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      console.error(error.data);
      setErrorStatus(error.status);
    }
  }, []);

  if (errorStatus == 404) {
    return (
      <ErrorPage
        title="404"
        titleSize="veryLarge"
        subtitle="Página não encontrada"
        description="Volte à página inicial"
        buttonNavigateTo={'/'}
        navigateButtonText="Página inicial"
        variant="warn"
      />
    );
  } else {
    return <ErrorPage buttonNavigateTo={0} icon={AlertTriangle} />;
  }
}
