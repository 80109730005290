import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from '@/lib/utils';

type ProgressProps = React.ComponentPropsWithoutRef<
  typeof ProgressPrimitive.Root
> & {
  showPercentage?: boolean;
};

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, showPercentage = true, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      'relative h-5 w-full overflow-hidden rounded-full bg-soft-gray',
      className,
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className="flex h-full w-full flex-1 items-center justify-end bg-pormade transition-all duration-300"
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    >
      {showPercentage ? <p className="mr-1 text-xs">{value}%</p> : ''}
    </ProgressPrimitive.Indicator>
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
