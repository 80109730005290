import { Search } from 'lucide-react';
import { Input } from './ui/input';
import { InputProps } from './ui/input';
import { cn } from '@/lib/utils';

export default function SearchInput({
  id,
  className,
  label,
  ...props
}: InputProps) {
  const inputId = id ?? `${Math.random() * 100}-${label}`;
  return (
    <div className={cn('relative w-full', className)}>
      <Input id={inputId} className="group pl-9" {...props} />
      <label
        htmlFor={inputId}
        className="center-center absolute bottom-0 left-2 top-0 z-10 my-auto cursor-text text-muted-foreground"
      >
        <Search className="p-[0.2rem]" />
      </label>
    </div>
  );
}
