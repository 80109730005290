import { cn } from '@/lib/utils';
import { HelpCircle, Loader2, X } from 'lucide-react';
import { useState } from 'react';
import myToast from '../Toast';
import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '../ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

type Confirmation = {
  title: string;
  description: string;
};

type ConfirmationDialogProps = Confirmation & {
  open: boolean;
};

type CheckboxStatusPros = {
  id: string;
  label?: string;
  title?: string;
  checked: boolean;
  disabled: boolean;
  onCheckedChange: () => Promise<unknown>;
  alert?: string;
  confirmation?: Confirmation;
};

export default function CheckboxStatus({
  id,
  label,
  title,
  checked,
  disabled,
  onCheckedChange,
  confirmation,
  alert = 'Somente quem assumiu o lead pode alterar o seu status.',
}: CheckboxStatusPros) {
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [confirmationDialogProps, setConfirmationDialogProps] =
    useState<ConfirmationDialogProps>({} as ConfirmationDialogProps);

  function closeDialog() {
    setConfirmationDialogProps((prev) => ({ ...prev, open: false }));
  }

  async function handleCheckedChange() {
    if (!disabled) {
      if (confirmation) {
        setConfirmationDialogProps({
          open: true,
          title: confirmation.title,
          description: confirmation.description,
        });
      } else {
        onCheckChange();
      }
    } else myToast.warn(alert);
  }

  async function onCheckChange() {
    closeDialog();

    setIsChecking(true);
    onCheckedChange().finally(() => {
      setIsChecking(false);
    });
  }

  return (
    <>
      {confirmation && confirmationDialogProps?.open ? (
        <Dialog open={confirmationDialogProps.open}>
          <DialogContent>
            <div className="mb-5 grid text-center">
              <DialogClose
                className="absolute right-4 top-4"
                onClick={closeDialog}
              >
                <X className="text-muted-foreground" size={28} />
              </DialogClose>
              <HelpCircle className={cn('mb-4 place-self-center')} size={40} />
              <DialogTitle className="text-xl font-bold">
                {confirmationDialogProps.title}
              </DialogTitle>
              <DialogDescription className="mt-4 flex items-center justify-center gap-2 text-base text-muted-foreground">
                {confirmationDialogProps.description}
              </DialogDescription>
            </div>

            <div className="mx-auto flex gap-5">
              <Button
                variant="destructive"
                onClick={() =>
                  setConfirmationDialogProps((prev) => ({
                    ...prev,
                    open: false,
                  }))
                }
              >
                Não
              </Button>
              <Button variant="outline" onClick={onCheckChange}>
                Sim
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        ''
      )}

      {isChecking ? (
        <label
          htmlFor={id}
          className={cn(
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            'flex w-fit items-center gap-2 text-start',
          )}
        >
          <span title="Alterando status">
            <Loader2 className="animate-spin" />
          </span>
          {label}
        </label>
      ) : (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <label
                htmlFor={id}
                className={cn(
                  disabled || isChecking
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer',
                  'flex w-fit items-center gap-2 text-start',
                )}
              >
                <Checkbox
                  id={id}
                  data-disabled={disabled || isChecking}
                  checked={checked}
                  onCheckedChange={handleCheckedChange}
                  className="rounded-full data-[disabled=true]:cursor-not-allowed data-[state=checked]:bg-pormade data-[disabled=true]:opacity-50 sm:size-5"
                />
                {label}
              </label>
            </TooltipTrigger>
            <TooltipContent side="bottom">
              {title ? title : !checked ? 'Clique para assumir o lead' : ''}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </>
  );
}
