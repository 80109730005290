declare global {
  interface String {
    limitSpaces: () => string;
    removeSpecialCharacters: () => string;
    segmentNameFormat: () => string;
    sheetNameInputFormat: () => string;
    makeSlug: (separator?: string) => string;
    toPTBRDateFormat: () => string;
  }
  interface Date {
    apiDateFormat: () => string;
    PTBRDateFormat: () => string;
  }
}

String.prototype.limitSpaces = function () {
  let value = this.valueOf();
  if (/^\s/.test(value)) {
    value = value.replace(/^\s/, '');
  }
  return value.replace(/\s+/g, ' ');
};

String.prototype.makeSlug = function (separator?: string) {
  let value = this.valueOf();
  const mapAccentsHex: any = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g,
  };

  for (let letter in mapAccentsHex) {
    let regularExpression = mapAccentsHex[letter];
    value = value.replace(regularExpression, letter);
  }

  value = value.toLowerCase();
  value = value.replace(/[^a-z0-9\-]/g, ' ');

  value = value.replace(/ {2,}/g, ' ');

  value = value.trim();
  if (separator) value = value.replace(/\s/g, '-');

  return value;
};

String.prototype.removeSpecialCharacters = function () {
  return this.valueOf().replace(/[^\w\sáéíóúâêîôûàèìòùäëïöüãõñç]/gi, '');
};

String.prototype.segmentNameFormat = function () {
  return this.valueOf().limitSpaces().toUpperCase();
};

String.prototype.sheetNameInputFormat = function () {
  return this.valueOf().limitSpaces().removeSpecialCharacters();
};

String.prototype.toPTBRDateFormat = function () {
  return new Date(this.valueOf()).toLocaleString('pt-BR', {
    dateStyle: 'long',
    timeStyle: 'medium',
  });
};

Date.prototype.apiDateFormat = function () {
  return this.toISOString().substring(0, 10);
};

Date.prototype.PTBRDateFormat = function () {
  return this.toLocaleString('pt-BR', {
    dateStyle: 'long',
    timeStyle: 'medium',
  });
};

export {};
