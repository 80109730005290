import dayjs from 'dayjs';

export function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

export function getStartAndEndOfMonth(month?: number): {
  startOfMonth: Date;
  endOfMonth: Date;
} {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  const startOfMonth = new Date(
    currentDate.getFullYear(),
    month ? month - 1 : currentMonth,
    1,
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    month ? month : currentMonth + 1,
    0,
  );

  return {
    startOfMonth,
    endOfMonth,
  };
}

export function getStartAndEndOfWeek(date: Date = new Date()): {
  startOfWeek: Date;
  endOfWeek: Date;
} {
  const currentDate = new Date(date);
  const dayOfWeek = currentDate.getDay();

  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);

  return { startOfWeek, endOfWeek };
}

export function getToday() {
  return dayjs().format('YYYY-MM-DD HH:mm:ss');
}
