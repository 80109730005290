import { defaultInputClassName } from '@/configs/input.config';
import { cn } from '@/lib/utils';
import { ReactNode, TextareaHTMLAttributes, forwardRef } from 'react';
import InputLabel from '../InputLabel';

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: ReactNode;
  onChangeValue?: (value: string) => void;
  errorMessage?: string;
  optional?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { label, className, onChangeValue, errorMessage, optional, ...props },
    ref,
  ) => {
    const textareaId = props.id ?? `${Math.random() * 100}-${label}`;
    return (
      <div>
        <InputLabel
          htmlFor={textareaId}
          required={props.required}
          optional={optional}
        >
          {label}
        </InputLabel>
        <textarea
          id={textareaId}
          className={cn(
            defaultInputClassName(errorMessage),
            'transition-none',
            className,
          )}
          ref={ref}
          {...(onChangeValue
            ? { onChange: (e) => onChangeValue(e.target.value) }
            : null)}
          {...props}
        />
      </div>
    );
  },
);
Textarea.displayName = 'Textarea';

export { Textarea };
