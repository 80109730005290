import { Dialog } from '@/components/Dialog';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { AlertTriangle, CheckCircle, HelpCircle, Info, X } from 'lucide-react';
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

const Icons = {
  question: HelpCircle,
  success: CheckCircle,
  error: AlertTriangle,
  warning: AlertTriangle,
  info: Info,
};

const Colors = {
  question: 'gray',
  success: 'pormade',
  error: 'pormade-red',
  warning: 'pormade-warn',
  info: 'gray',
};

type CommonProps = {
  text?: string | React.ReactNode;
  title?: string | React.ReactNode;
  type: TypeDialog;
};
type TypeDialog = 'question' | 'success' | 'error' | 'warning' | 'info';
interface IToastInfo extends CommonProps {
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  closeButton?: boolean;
}
interface IDialog {
  close: () => void;
  open: (toastInfo: IToastInfo) => Promise<{ isConfirmed: boolean } | null>;
}
interface IDialogComponent extends CommonProps {
  open: boolean;
  closeButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  cancelButtonColor?: string;
  refCancel: React.ForwardedRef<HTMLButtonElement>;
  refClose: React.ForwardedRef<HTMLButtonElement>;
  setOpen: (state: boolean) => void;
}

const DialogComponent = forwardRef<HTMLButtonElement, IDialogComponent>(
  ({ closeButton = false, ...props }, ref) => {
    const Icon = Icons[props.type];
    const color = Colors[props.type];
    const textColor = `text-${color}`;
    const shadowColor = `shadow-${color}/50`;
    return (
      <Dialog
        open={props.open}
        className={cn(
          'w-fit border border-muted-foreground shadow-lg',
          shadowColor,
        )}
        closeButton={false}
        content={
          <div className="mb-5 grid text-center">
            {closeButton ? (
              <button className="absolute right-4 top-4" ref={props.refClose}>
                <X className="text-muted-foreground" size={28} />
              </button>
            ) : (
              ''
            )}
            <Icon
              className={cn('mb-4 place-self-center', textColor)}
              size={40}
            />
            {props.title ? (
              typeof props.title == 'string' ? (
                <p className="text-xl font-bold">{props.title}</p>
              ) : (
                props.title
              )
            ) : null}
            {props.text ? (
              typeof props.text == 'string' ? (
                <p className="mt-4 flex items-center justify-center gap-2 text-base text-muted-foreground">
                  {props.text}
                </p>
              ) : (
                props.text
              )
            ) : null}
          </div>
        }
        footer={
          <div className="flex gap-5">
            <Button variant="destructive" ref={props.refCancel}>
              {props.cancelButtonText}
            </Button>
            <Button ref={ref} variant="outline">
              {props.confirmButtonText}
            </Button>
          </div>
        }
      />
    );
  },
);

const DialogContext = createContext({} as IDialog);
export const useDialog = () => useContext(DialogContext);
export function DialogProvider({ children }: { children: React.ReactNode }) {
  const [toastInfo, setToastInfo] = useState<IToastInfo>({
    type: 'success',
    text: '',
    title: '',
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const cancelToastButton = useRef<HTMLButtonElement>(null);
  const closeToastButton = useRef<HTMLButtonElement>(null);
  const confirmToastButton = useRef<HTMLButtonElement>(null);
  const closeToast = useCallback(() => setIsOpen(false), []);

  async function openToast(
    info: IToastInfo,
  ): Promise<{ isConfirmed: boolean } | null> {
    return new Promise((resolve) => {
      setToastInfo(info);
      setIsOpen(true);
      const confirmCallback = (event: Event) => {
        if (
          confirmToastButton.current &&
          confirmToastButton.current.contains(event.target as Node)
        ) {
          resolve({ isConfirmed: true });
          closeToast();
          removeEventListeners();
        }
      };

      const cancelCallback = (event: Event) => {
        if (
          cancelToastButton.current &&
          cancelToastButton.current.contains(event.target as Node)
        ) {
          resolve({ isConfirmed: false });
          closeToast();
          removeEventListeners();
        }
      };

      const closeCallback = (event: Event) => {
        if (
          closeToastButton.current &&
          closeToastButton.current.contains(event.target as Node)
        ) {
          resolve(null);
          closeToast();
          removeEventListeners();
        }
      };

      const removeEventListeners = () => {
        document.removeEventListener('click', confirmCallback);
        document.removeEventListener('click', cancelCallback);
        document.removeEventListener('click', closeCallback);
      };

      document.addEventListener('click', confirmCallback);
      document.addEventListener('click', cancelCallback);
      document.addEventListener('click', closeCallback);
      return () => {
        removeEventListeners();
      };
    });
  }

  return (
    <DialogContext.Provider value={{ close: closeToast, open: openToast }}>
      {isOpen ? (
        <DialogComponent
          open={isOpen}
          refCancel={cancelToastButton}
          refClose={closeToastButton}
          ref={confirmToastButton}
          setOpen={(state: boolean) => setIsOpen(state)}
          {...toastInfo}
        />
      ) : null}
      {children}
    </DialogContext.Provider>
  );
}
