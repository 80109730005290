import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import axios, { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ddds } from '@/constants/ddd';
import useLoading from '@/hooks/useLoading';
import { api } from '@/services/api/api';
import { TCrmOrigin } from '@/services/api/api.responses';
import { getAxiosErrorMessage } from '@/services/api/api.utils';
import { IBGECity, IBGEState, TSelectItem } from '@/types';
import { getToday } from '@/utils/date.utils';
import {
  TRegisterLeadSchema,
  registerLeadSchema,
} from '../../../schemas/register-lead-schema';

import { ComboBoxResponsive } from '@/components/ComboBoxResponsive';
import DefaultPage from '@/components/DefaultPage';
import { NumberInputMask } from '@/components/NumberInputMask';
import SeparatorTitle from '@/components/SeparatorTitle';
import Subtitle from '@/components/Subtitle';
import Title from '@/components/Title';
import myToast from '@/components/Toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Loader, UserPlus } from 'lucide-react';

type CRMJob = { id: number; profissao: string };

export default function RegisterLead() {
  const navigate = useNavigate();
  const params = useParams();
  const originId = params.origemId;
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TRegisterLeadSchema>({
    resolver: zodResolver(registerLeadSchema),
    mode: 'onChange',
  });

  const [origin, setOrigin] = useState<TCrmOrigin>();

  const [isLoadingStates, startLoadingStates] = useLoading();
  const [IBGEStates, setIBGEStates] = useState<TSelectItem[]>();

  const [isLoadingCities, startLoadingCities] = useLoading();
  const [IBGECities, setIBGECities] = useState<TSelectItem[]>();

  const [isLoadingCRMJobs, startLoadCRMJobs] = useLoading(true);
  const [CRMJobs, setCRMJobs] = useState<TSelectItem[]>();

  const [isRegistering, startRegister] = useLoading(false);

  const currentJobId = watch('profissao');
  const currentJob = CRMJobs?.find((job) => job.value == currentJobId)?.label;

  const ibgeCode = watch('ibge');
  const stateId = watch('estado_id');
  const phone = watch('celular');

  const phoneDDD = phone?.removeSpecialCharacters().substring(0, 2);
  const currentDDDState = ddds[Number(phoneDDD)] ?? '';

  const inputProps = (field: keyof TRegisterLeadSchema) => {
    return {
      errorMessage: errors[field]?.message,
      ...register(field),
    };
  };

  function onSubmit(data: TRegisterLeadSchema) {
    startRegister(() =>
      api
        .post('/leads/cadastrar', {
          ...data,
          ibge: Number(data.ibge),
          crm_origem_id: Number(originId),
          data_coleta: getToday(),
          profissao: currentJob,
          usuario_registrador: null,
          telefone: null,
        })
        .then(() => {
          myToast.success('Lead cadastrado com sucesso!');
          reset();
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            if (error.response?.status == 409) {
              reset();
            }
          }
          myToast.error(
            getAxiosErrorMessage(
              error,
              'Ocorreu um erro ao cadastrar o Lead, informe os desenvolvedores.',
            ),
          );
        }),
    );
  }
  useEffect(() => {
    if (stateId) {
      startLoadingCities(() =>
        axios
          .get<IBGECity[]>(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`,
          )
          .then(({ data }) =>
            setIBGECities(
              data.map((city) => {
                return { value: String(city.id), label: city.nome };
              }),
            ),
          )
          .catch((error) =>
            myToast.error(
              getAxiosErrorMessage(
                error,
                'Não foi possível obter as cidades do estado. Tente novamente mais tarde.',
              ),
            ),
          ),
      );
    }
  }, [stateId]);

  useEffect(() => {
    startLoadingStates(() =>
      axios
        .get<IBGEState[]>(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
        )
        .then(({ data }) =>
          setIBGEStates(
            data.map((state) => {
              return {
                value: String(state.id),
                label: `${state.nome} (${state.sigla})`,
              };
            }),
          ),
        )
        .catch((error) =>
          myToast.error(
            getAxiosErrorMessage(
              error,
              'Não foi possível obter os estados. Tente novamente mais tarde.',
            ),
          ),
        ),
    );

    if (originId) {
      api
        .get<TCrmOrigin>('crm/origens', { params: { id_origem: originId } })
        .then(({ data }) => setOrigin(data))
        .catch((error) => {
          myToast.error(getAxiosErrorMessage(error, 'Falha ao obter origem'));
          navigate('/leads');
        });
    } else {
      navigate('/leads');
    }
    startLoadCRMJobs(() =>
      api.get<CRMJob[]>('crm/profissoes').then(({ data }) =>
        setCRMJobs([
          ...data.map((job) => {
            return { label: job.profissao, value: String(job.id) };
          }),
          { label: 'OUTRO', value: '-1' },
        ]),
      ),
    );
  }, [originId]);
  return (
    <DefaultPage
      className="mx-auto max-w-4xl"
      goBackTo={{ to: `/leads/${originId}` }}
    >
      <Title marginBottom>Cadastro de Lead</Title>
      {origin?.name ? (
        <Subtitle className="mb-10">
          <p>{origin.name}</p>
        </Subtitle>
      ) : (
        ''
      )}
      <form
        onSubmit={handleSubmit(onSubmit, () =>
          myToast.error('Verifique os campos.'),
        )}
        className="mb-20 grid w-full"
      >
        <div className="grid gap-10">
          <Input
            autoFocus
            required
            disabled={isRegistering}
            label="Nome"
            placeholder="Nome Completo"
            {...inputProps('nome')}
          />

          <ComboBoxResponsive
            required
            value={currentJobId}
            label="Profissão"
            title="Profissão"
            description="Selecione uma profissão"
            errorMessage={errors.profissao?.message}
            options={CRMJobs}
            placeholder={
              isLoadingCRMJobs ? 'Buscando profissões..' : 'Selecione...'
            }
            disabled={isLoadingCRMJobs || isRegistering}
            onChange={(option) => {
              if (option) {
                setValue('profissao', option.value);
                clearErrors('profissao');
              }
            }}
            onBlur={() => currentJobId && clearErrors('profissao')}
          />

          <ComboBoxResponsive
            required
            value={stateId}
            label="Estado (UF)"
            title="Estado (UF)"
            description="Selecione um estado"
            errorMessage={errors.estado_id?.message}
            options={IBGEStates}
            placeholder={
              isLoadingStates ? 'Buscando estados..' : 'Selecione...'
            }
            disabled={!IBGEStates || isLoadingStates || isRegistering}
            onChange={(option) => {
              if (option) {
                setValue('ibge', '');
                setValue('estado_id', option.value);
                clearErrors('estado_id');
              }
            }}
            onBlur={() => stateId && clearErrors('estado_id')}
          />

          <ComboBoxResponsive
            required
            value={String(ibgeCode)}
            label="Cidade"
            title="Cidade"
            description="Selecione uma cidade"
            placeholder={
              !stateId
                ? 'Selecione um estado...'
                : isLoadingCities
                  ? 'Buscando cidades...'
                  : 'Selecione...'
            }
            errorMessage={errors.ibge?.message}
            options={IBGECities}
            disabled={!stateId || isLoadingCities || isRegistering}
            onChange={(option) => {
              if (option) {
                setValue('ibge', option.value);
                clearErrors('ibge');
              }
            }}
            onBlur={() => ibgeCode && clearErrors('ibge')}
          />

          <NumberInputMask
            mask="(__) _____-____"
            disabled={isRegistering}
            required
            label={`Celular ${currentDDDState ? `(${currentDDDState})` : ''}`}
            errorMessage={errors.celular?.message}
            {...register('celular')}
          />

          <SeparatorTitle>Opcionais</SeparatorTitle>
          <Input
            optional
            disabled={isRegistering}
            type="email"
            label="Email"
            placeholder="email@email.com"
            {...inputProps('email')}
          />

          <Input
            optional
            disabled={isRegistering}
            label="Empresa"
            placeholder="Nome da empresa"
            {...inputProps('empresa')}
          />

          <Textarea
            optional
            label="Observações"
            placeholder="Digite aqui..."
            disabled={isRegistering}
            className="min-h-32"
            {...inputProps('observacoes')}
          />
        </div>

        <footer
          className={cn(
            isRegistering ? 'bg-pormade/50' : 'bg-pormade',
            'center-center fixed bottom-0 left-0 right-0 block w-full text-white backdrop-blur-sm sm:hidden',
          )}
        >
          <button
            disabled={isRegistering}
            type="submit"
            className="center-center text-md flex size-full items-center gap-2 py-5 font-semibold transition-colors duration-200 hover:bg-pormade-hover"
          >
            {isRegistering ? (
              <>
                <Loader className="animate-spin" /> Cadastrando
              </>
            ) : (
              <>
                <UserPlus /> Cadastrar Lead
              </>
            )}
          </button>
        </footer>

        <Button
          disabled={isRegistering}
          className="mt-20 hidden items-center gap-2 sm:flex"
          type="submit"
        >
          {isRegistering ? (
            <>
              <Loader className="animate-spin" /> Cadastrando
            </>
          ) : (
            <>
              <UserPlus /> Cadastrar Lead
            </>
          )}
        </Button>
      </form>
    </DefaultPage>
  );
}
