import FileCard from '@/components/FileCard';
import { TFileInfo } from '@/types';

type FileCardsProps = {
  removeFile: (file: TFileInfo) => void;
  files: TFileInfo[];
  className?: string;
};

export default function FileCards({
  files,
  removeFile,
  className,
}: FileCardsProps) {
  return (
    <ul className={`${className ?? ''} relative flex flex-col gap-3 p-5`}>
      {files?.map((file, i) => (
        <div key={i}>
          <FileCard file={file} removeFile={(file) => removeFile(file)} />
        </div>
      ))}
    </ul>
  );
}
