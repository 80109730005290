import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Loader } from 'lucide-react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-all focus-visible:outline-none disabled:pointer-events-none disabled:opacity-70',
  {
    variants: {
      variant: {
        default:
          'bg-pormade hover:bg-pormade-hover dark:bg-pormade-hover dark:hover:bg-pormade text-white font-semibold',
        destructive:
          'bg-pormade-red hover:bg-pormade-red-hover dark:bg-pormade-red-hover  dark:hover:bg-pormade-red text-white',
        outline:
          'border border-muted-foreground bg-background text-foreground hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-12 px-10 py-3 text-md',
        sm: 'h-9 rounded-md px-3',
        md: 'h-10 rounded-md px-6',
        lg: 'h-12 rounded-md px-9',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  promise?: () => Promise<any>;
  loaderMessage?: string;
  error?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      promise,
      loaderMessage,
      error,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
      if (props.onClick) props.onClick(event);
      if (promise) {
        setIsLoading(true);
        promise()?.finally(() => setIsLoading(false));
      }
    }

    return (
      <Comp
        ref={ref}
        className={cn(
          isLoading ? 'animate-pulse' : null,
          buttonVariants({ variant, size, className }),
          error ? 'border-pormade-red' : '',
          'select-none',
          'hover:scale-[98%]',
        )}
        onClick={handleClick}
        {...(isLoading ? { disabled: true } : null)}
        {...props}
      >
        {isLoading ? <Loader className="mr-2 animate-spin" /> : null}
        {isLoading ? (loaderMessage ?? 'Carregando') : props.children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
