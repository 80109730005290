import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Dialog as ShadcnDialog,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { DialogProps as UIDialogProps } from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { ReactNode } from 'react';

type DialogProps = UIDialogProps & {
  title?: string;
  description?: string;
  footer?: ReactNode;
  content?: ReactNode;
  children?: ReactNode;
  className?: string;
  closeButton?: boolean;
  onClose?: () => void;
};

export function Dialog({
  title,
  description,
  content,
  footer,
  closeButton = true,
  onClose,
  className,
  children,
  ...props
}: DialogProps) {
  return (
    <ShadcnDialog {...props}>
      <DialogContent {...props} className={cn('', className)}>
        {closeButton ? (
          <DialogClose className="absolute right-4 top-4" onClick={onClose}>
            <X className="text-dark-gray" size={28} />
          </DialogClose>
        ) : (
          ''
        )}
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        {content ?? children}
        <DialogFooter className="m-auto">{footer}</DialogFooter>
      </DialogContent>
    </ShadcnDialog>
  );
}
