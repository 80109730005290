import ProfilePic from '@/components/ProfileSideBar/ProfilePic';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { useDialog } from '@/hooks/useDialog';
import { cn } from '@/lib/utils';
import { TUserProfile } from '@/types';
import { DoorOpen, Headset, Plus, SwitchCamera, Trash2, X } from 'lucide-react';
import { ChangeEvent, ReactNode } from 'react';
import { version } from '../../../package.json';

type UserProfileProps = {
  isAuthenticated: boolean;
  profile?: TUserProfile;
  children: ReactNode;
  onLogout: () => void;
  changeProfilePic: (file: File) => Promise<unknown>;
  removeProfilePicture: () => void;
};

export default function ProfileSideBar({
  isAuthenticated,
  profile,
  changeProfilePic,
  removeProfilePicture,
  onLogout,
  children,
}: UserProfileProps) {
  const dialog = useDialog();

  function handleChangeProfilePicture(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files && files[0]) {
      changeProfilePic(files[0]).finally(() => (event.target.value = ''));
    }
  }

  function handleRemoveProfilePicture() {
    dialog
      .open({
        type: 'error',
        title: (
          <h1 className="medium-text px-5 font-bold">REMOVER FOTO DE PERIL?</h1>
        ),
        text: 'Não será possível recuperar a foto.',
        confirmButtonText: 'Manter',
        cancelButtonText: 'Remover',
        showCancelButton: true,
        showConfirmButton: true,
      })
      .then((response) => {
        if (!response?.isConfirmed) {
          removeProfilePicture();
        }
      });
  }

  if (isAuthenticated && profile)
    return (
      <Sheet modal={true}>
        <SheetTrigger>{children}</SheetTrigger>
        <SheetContent
          side={'right'}
          className="sidebar-shadow flex h-full flex-col border-pormade bg-black/85 text-white backdrop-blur-[4px]"
          aria-describedby={undefined}
        >
          <SheetTitle className="hidden">Perfil</SheetTitle>
          <SheetClose className="absolute right-6 top-4">
            <X size={32} />
          </SheetClose>
          <div className="relative mt-10 flex flex-col items-center justify-center">
            <div className="mb-6 w-[70%]">
              <div className="relative max-w-full pb-[100%]">
                <ProfilePic />
                <Dialog>
                  <DialogTrigger asChild>
                    <button className="center-center absolute bottom-3 right-3 size-10 rounded-full bg-pormade p-2 ring-2 ring-white transition-colors duration-200 hover:bg-pormade-hover">
                      <SwitchCamera className="size-full" />
                    </button>
                  </DialogTrigger>

                  <DialogContent className="max-w-[50rem]" closeButton>
                    <DialogHeader>
                      <DialogTitle>
                        {profile.picture ? 'Alterar' : 'Adicionar'} foto de
                        perfil
                      </DialogTitle>
                      <DialogDescription>
                        {profile.picture ? 'Altere sua' : 'Adicione uma'} foto
                        de perfil
                      </DialogDescription>
                    </DialogHeader>
                    <div className="mx-auto mb-8 mt-5 w-[50%]">
                      <div className="relative max-w-full pb-[100%]">
                        <ProfilePic />
                      </div>
                    </div>

                    <div
                      className={cn(
                        profile.picture ? 'grid-cols-2' : 'grid-cols-1',
                        'mb-5 grid grid-flow-col gap-2 px-5',
                      )}
                    >
                      <Button size={'md'} className="relative">
                        <label
                          title="Escolha uma foto"
                          htmlFor="profilePictureInputFile"
                          className="absolute z-10 size-full cursor-pointer opacity-0"
                        />
                        <input
                          id="profilePictureInputFile"
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          className="hidden"
                          onChange={handleChangeProfilePicture}
                        />
                        {profile.picture ? (
                          <>
                            <SwitchCamera className="mr-2" />
                            Alterar
                          </>
                        ) : (
                          <>
                            <Plus className="mr-2" /> Adicionar
                          </>
                        )}
                      </Button>
                      <Button
                        data-hidden={!profile.picture}
                        title="Remover foto de perfil"
                        onClick={handleRemoveProfilePicture}
                        size={'md'}
                        variant={'destructive'}
                        className="data-[hidden=true]:hidden"
                      >
                        <Trash2 className="mr-2" /> Remover foto
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            <p className="base-text text-center font-medium capitalize">
              {profile?.user?.toLowerCase()}
            </p>
          </div>
          <a
            href={import.meta.env.VITE_SUPPORT_URL}
            target="_blank"
            className="mx-auto mt-10 flex w-full gap-2"
          >
            <Button
              variant="outline"
              className="w-full gap-2 bg-transparent text-white hover:bg-white/10 hover:text-white"
            >
              <Headset /> Suporte
            </Button>
          </a>

          <div className="mt-auto">
            <Button
              variant="outline"
              onClick={onLogout}
              className="flex w-full items-center justify-center gap-2 border-pormade-red bg-transparent text-white hover:bg-pormade-red/10 hover:text-white"
            >
              <DoorOpen /> Sair
            </Button>

            <p className="mt-4 text-center text-base text-muted-foreground sm:text-lg">
              Versão: {version}
            </p>
          </div>
        </SheetContent>
      </Sheet>
    );
  else return <>{children}</>;
}
