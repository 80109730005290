import React from 'react';

type TitleProps = {
  children: React.ReactNode | string;
  className?: string;
};

export default function Subtitle({ children, className }: TitleProps) {
  return (
    <div>
      <h1
        className={`${className && className} text-base sm:text-lg md:text-xl`}
      >
        {children}
      </h1>
    </div>
  );
}
