import { useAuth } from '@/contexts/authContext';
import { cn } from '@/lib/utils';
import { User } from 'lucide-react';
import { ForwardedRef, forwardRef } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';

type ProfilePicProps = {
  picUrl?: string;
  aspect?: 'square' | 'rounded';
};

const Pic = forwardRef(
  (
    { picUrl, aspect = 'rounded' }: ProfilePicProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <>
      <div
        ref={ref}
        className={cn(
          aspect == 'rounded' ? 'rounded-full' : 'rounded-md',
          'center-center absolute inset-0 bg-gray bg-cover bg-center bg-no-repeat ring-2 ring-pormade',
        )}
        style={{ backgroundImage: `url(${picUrl})` }}
      >
        <User
          strokeWidth={1.5}
          data-hidden={picUrl ? true : false}
          className="size-[80%] text-white data-[hidden=true]:hidden"
        />
      </div>
    </>
  ),
);

export default function ProfilePic() {
  const { profile } = useAuth();
  const profilePic = profile?.picture;
  if (profilePic)
    return (
      <Dialog>
        <DialogTrigger className="absolute inset-0 cursor-pointer">
          <Pic picUrl={profilePic} />
        </DialogTrigger>
        <DialogContent
          aria-describedby={undefined}
          className="aspect-square w-[85%] max-w-[40rem] rounded-full border-none bg-transparent sm:w-[80%] sm:rounded-full md:w-[70%] lg:w-[50%]"
        >
          <DialogTitle className="hidden">{profile.user}</DialogTitle>
          <Pic picUrl={profilePic} aspect="square" />
        </DialogContent>
      </Dialog>
    );
  else return <Pic />;
}
