import { Progress } from '@/components/ui/progress';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { api } from '@/services/api/api';
import { TCrmOrigin } from '@/services/api/api.responses';
import { Loader2 } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';

export type ToastLoaderComponentProps = {
  icon?: ReactNode;
  title?: string;
  description: string;
  percentage?: number;
  className?: string;
  crmOriginId?: number;
};

export function ToastLoaderComponent({
  title = 'Processando planilha',
  description,
  percentage,
  className,
  icon = <Loader2 size={20} className="animate-spin items-center" />,
  crmOriginId,
}: ToastLoaderComponentProps) {
  const [crmOriginDetails, setCrmOriginDetails] = useState<TCrmOrigin>();

  useEffect(() => {
    if (crmOriginId) {
      api
        .get<TCrmOrigin>('/crm/origens', {
          params: { id_origem: crmOriginId },
        })
        .then(({ data }) => setCrmOriginDetails(data));
    }
  }, [crmOriginId]);

  return (
    <div className={cn('w-full select-none', className)}>
      <div className="flex items-center gap-3">
        {icon}
        <p className="text-lg font-medium">{title}</p>
      </div>
      <Separator className="my-2 bg-dark-gray" />
      {crmOriginDetails ? (
        <p className="my-1 text-soft-gray">{crmOriginDetails?.name}</p>
      ) : (
        ''
      )}
      <p className="animate-pulse text-soft-gray">{description}</p>
      {percentage ? <Progress value={percentage} className="mt-2 h-3" /> : ''}
    </div>
  );
}
