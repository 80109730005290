import { useEffect, useRef, useState } from 'react';

type UseCounterOptions = {
  duration?: number;
  delay?: number;
};

export function useCounter(
  value: number,
  userOptions?: UseCounterOptions,
): { counter: number; isFinished: boolean } {
  const options = {
    duration: userOptions?.duration ?? 2000,
    delay: userOptions?.delay ?? 900,
  };

  const { delay, duration } = options;

  const [counter, setCounter] = useState<number>(0);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const counterController = useRef<number>(0);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (value > 0) {
      const getSumValue = () => {
        const sumValue = Number((value / 100).toFixed(0));
        if (sumValue > 0) {
          return sumValue;
        }
        return 1;
      };

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      const sumvalue = getSumValue();

      const intervalDuration = (duration / value) * sumvalue;

      setIsFinished(false);

      timeoutRef.current = setTimeout(() => {
        intervalRef.current = setInterval(() => {
          if (counterController.current < value) {
            counterController.current += sumvalue;
            setCounter(counterController.current);
          } else {
            setCounter(value);
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
            }

            setIsFinished(true);
          }
        }, intervalDuration);
      }, delay);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [value, duration, delay]);

  return { counter, isFinished };
}
