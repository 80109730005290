import { cn } from '@/lib/utils';

function Skeleton({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'relative animate-pulse overflow-hidden rounded-md bg-foreground/5 outline outline-1 outline-foreground/20',
        className,
      )}
      {...props}
    >
      <span className="absolute size-full h-full w-[30%] animate-skeleton-shime bg-gradient-to-r from-neutral-200/10 via-white to-neutral-200/10 opacity-50 dark:via-white/40" />
      {children}
    </div>
  );
}

export { Skeleton };
