import { tabsNames } from '@/configs/sheet.config';
import { cn } from '@/lib/utils';
import { ChevronRight, StickyNote } from 'lucide-react';
import { ComponentProps } from 'react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

type SelectTabProps = Omit<ComponentProps<'div'>, 'onSelect'> & {
  selectedTab: string;
  onSelect: (tab: string) => void;
  tabs: string[];
  disabled?: boolean;
  className?: string;
};

export default function SelectTab({
  tabs,
  selectedTab,
  onSelect,
  disabled,
  className,
  ...props
}: SelectTabProps) {
  return (
    <>
      <div
        data-disabled={disabled}
        className={cn(
          'hidden flex-wrap justify-center gap-4 self-start data-[disabled=true]:animate-pulse md:flex md:justify-start',
          className,
        )}
        {...props}
      >
        <Button
          variant={selectedTab == 'Geral' ? 'default' : 'outline'}
          onClick={() => onSelect('Geral')}
          disabled={disabled}
        >
          Geral
        </Button>
        {tabs?.map((tab, index) =>
          tab.toLowerCase() != 'geral' ? (
            <Button
              variant={selectedTab == tab ? 'default' : 'outline'}
              onClick={() => onSelect(tab)}
              key={`${tab}-${index}`}
              disabled={disabled}
            >
              {tabsNames[tab] ?? tab}
            </Button>
          ) : (
            ''
          ),
        )}
      </div>
      <div
        className={cn(
          'flex justify-center text-muted-foreground md:hidden',
          className,
        )}
      >
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="group" disabled={disabled}>
            <Button variant={'outline'} disabled={disabled}>
              <StickyNote className="mr-2" />{' '}
              {tabsNames[selectedTab] ?? selectedTab}
              <ChevronRight className="ml-2 h-4 w-4 transition-transform duration-200 group-data-[state=open]:rotate-90" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            className="w-full border-muted-foreground bg-background"
          >
            <DropdownMenuCheckboxItem
              checked={selectedTab == 'Geral'}
              onSelect={() => onSelect('Geral')}
              className="max-w-[90%] text-base"
            >
              Geral
            </DropdownMenuCheckboxItem>
            {tabs?.map((tab, index) =>
              tab.toLowerCase() != 'geral' ? (
                <DropdownMenuCheckboxItem
                  checked={selectedTab == tab}
                  key={`${tab}-${index}`}
                  onSelect={() => onSelect(tab)}
                  className="max-w-[90%] text-base"
                >
                  {tabsNames[tab] ?? tab}
                </DropdownMenuCheckboxItem>
              ) : (
                ''
              ),
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
}
