import { z } from 'zod';

export const registerSheetSchema = z.object({
  files: z
    .array(z.custom<File>(), { required_error: 'Adicione planilha(s).' })
    .min(1, 'Adicione planilha(s).'),
  sheet_crm_origin_id: z
    .string({ required_error: 'Selecione uma origem.' })
    .min(1, 'Selecione uma origem.')
    .refine((value) => !isNaN(Number(value)), 'Origem inválida.'),
  app_crm_origin_id: z.null().or(
    z
      .string({ required_error: 'Selecione uma origem.' })
      .min(1, 'Selecione uma origem.')
      .refine((value) => !isNaN(Number(value)), 'Origem inválida.'),
  ),
});

export type TRegisterSheetSchema = z.infer<typeof registerSheetSchema>;
