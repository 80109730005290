import { useDialog } from '@/hooks/useDialog';
import useLoading from '@/hooks/useLoading';
import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import { ComponentProps, MouseEvent } from 'react';
import myToast from './Toast';

type DownloadButtonProps = Pick<
  ComponentProps<'div'>,
  'className' | 'title' | 'children' | 'onClick'
> & {
  request: (includeAssumed: boolean) => Promise<unknown>;
  downloadText?: string;
  loadingText?: string;
  disabled?: boolean;
};
export default function DownloadSheetButton({
  request,
  downloadText = 'Baixar',
  loadingText = 'Baixando',
  className,
  disabled,
  onClick,
  ...props
}: DownloadButtonProps) {
  const [isDownloading, startDownload] = useLoading();

  const dialog = useDialog();

  function handleDownload(event: MouseEvent<HTMLDivElement>) {
    if (disabled || isDownloading) {
      return;
    }

    if (onClick) {
      onClick(event);
    }
    dialog
      .open({
        type: 'question',
        title: (
          <h1 className="medium-text px-5 font-bold">
            EXTRAIR LEADS ASSUMIDOS?
          </h1>
        ),
        text: 'Deseja incluir os leads já assumidos?',
        confirmButtonText: 'SIM',
        cancelButtonText: 'NÃO',
        showCancelButton: true,
        showConfirmButton: true,
        closeButton: true,
      })
      .then((response) => {
        if (response) {
          download(response.isConfirmed);
        }
      });
  }

  function download(includeAssumed: boolean) {
    const toastId = myToast.info('Baixando planilha de leads ...', {
      autoClose: false,
      icon: <Loader2 className="animate-spin" />,
      closeButton: false,
    });

    startDownload(() =>
      request(includeAssumed).finally(() => toastId && myToast.close(toastId)),
    );
  }

  return (
    <div
      onClick={handleDownload}
      data-disabled={disabled}
      className={cn(
        'cursor-pointer data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
        className,
      )}
      {...props}
    />
  );
}
