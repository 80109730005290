import { cn } from '@/lib/utils';
import { Button } from './ui/button';
import { ArrowUpDown } from 'lucide-react';
import { TOrderBy } from '@/types';

type OrderByProps = {
  onChange: (item: TOrderBy[]) => void;
  items: TOrderBy[];
  className?: string;
  disabled?: boolean;
};

export default function OrderBy({
  items,
  onChange,
  disabled,
  className,
}: OrderByProps) {
  function handleToggleOrderBy(orderItem: TOrderBy) {
    const newOrder = items.map((item) =>
      item.name === orderItem.name
        ? {
            name: item.name,
            selected: true,
            order: item.selected
              ? item.order == 'asc'
                ? 'desc'
                : 'asc'
              : item.order,
          }
        : { selected: false, name: item.name, order: item.order },
    );

    onChange(newOrder);
  }

  return (
    <div className={cn('flex flex-wrap justify-center gap-3', className)}>
      {items.map((item: TOrderBy, index: number) => (
        <Button
          disabled={disabled}
          key={index}
          title={`Ordenar por ${item.name.toLowerCase()}`}
          variant="outline"
          className={cn(
            item.selected
              ? 'bg-pormade text-white hover:bg-pormade-hover hover:text-white dark:bg-pormade-hover dark:hover:bg-pormade'
              : 'text-muted-foreground',
          )}
          onClick={() => handleToggleOrderBy(item)}
        >
          {item.name}
          <ArrowUpDown
            className={cn(
              'ml-2 size-5 transition-transform duration-200',
              item.order == 'desc' ? '-scale-y-100' : '',
            )}
          />
        </Button>
      ))}
    </div>
  );
}
