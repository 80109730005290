import { defaultInputClassName } from '@/configs/input.config';
import { cn } from '@/lib/utils';
import { InputMask } from '@react-input/mask';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import InputLabel from './InputLabel';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  errorMessage?: string;
  onChangeValue?: (value: string) => void;
  mask: string;
}

const NumberInputMask = forwardRef<HTMLInputElement, InputProps>(
  (
    { type, label, mask, className, errorMessage, onChangeValue, ...props },
    ref,
  ) => {
    const inputId = props.id ?? `${Math.random() * 100}-${label}`;

    return (
      <div>
        {label ? (
          <InputLabel htmlFor={inputId} required={props.required}>
            {label}
          </InputLabel>
        ) : (
          ''
        )}

        <div className="relative">
          <InputMask
            mask={mask}
            placeholder={mask}
            replacement={{ _: /\d/ }}
            ref={ref}
            id={inputId}
            type={'text'}
            className={cn(defaultInputClassName(errorMessage), className)}
            {...(onChangeValue
              ? { onChange: (e) => onChangeValue(e.target.value) }
              : null)}
            {...props}
          />
        </div>
        <span
          data-hidden={!errorMessage}
          className="text-sm font-medium text-pormade-red data-[hidden=true]:hidden"
        >
          {errorMessage}
        </span>
      </div>
    );
  },
);
NumberInputMask.displayName = 'Input';

export { NumberInputMask };
