import { ReactNode } from 'react';
import { Separator } from './ui/separator';
import { cn } from '@/lib/utils';

type SeparatorTitleProps = { children: ReactNode; className?: string };

export default function SeparatorTitle({
  children,
  className,
}: SeparatorTitleProps) {
  return (
    <div
      className={cn(
        'medium-text my-5 flex items-center justify-center gap-5 overflow-hidden font-semibold text-muted-foreground',
        className,
      )}
    >
      <Separator />
      <div className="text-nowrap">{children}</div>
      <Separator />
    </div>
  );
}
