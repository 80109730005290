import { useLocation } from 'react-router-dom';
import { TRouteItem } from '@/types';

import { Menu } from 'lucide-react';

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { ScrollArea } from '@/components/ui/scroll-area';
import SideBarGroupItem from './SideBarGroupItem';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { useEffect, useState } from 'react';

type SideBarProps = {
  className?: string;
  side?: 'top' | 'bottom' | 'left' | 'right' | null;
  contents: TRouteItem[];
};

export default function SideBar({
  className,
  contents,
  side = 'left',
}: SideBarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const locationPaths = location.pathname.split('/').filter((path) => path);

  function checkIfIsSelected(href?: string) {
    if (!href) return false;
    if (location.pathname == href) return true;
    return locationPaths.includes(href.replace('/', ''));
  }

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className={`${className}`}>
      <Sheet open={isOpen} onOpenChange={setIsOpen} modal={false}>
        <SheetTrigger className="flex items-center justify-center">
          <Menu className="h-full text-white" />
        </SheetTrigger>
        <SheetContent
          className="sidebar-shadow border-pormade bg-black/85 backdrop-blur-[4px] lg:hidden"
          side={side}
        >
          <SheetClose>
            <SheetTitle>
              <Menu className="text-white" />
            </SheetTitle>
          </SheetClose>
          <ScrollArea className="mt-10 h-[90%] pr-5">
            <div className="grid gap-4">
              {contents?.map((content, index) => {
                const ContentIcon = content.icon;
                if (!content.href) {
                  return (
                    <Accordion
                      collapsible
                      type="single"
                      className="text-white"
                      key={`${content.title}-${index}`}
                    >
                      <AccordionItem value="item-1">
                        <AccordionTrigger
                          data-selected={
                            !!content.items?.find((item) =>
                              checkIfIsSelected(item.href),
                            )
                          }
                          className="group flex justify-between gap-4 py-2"
                          title={`Ver ${content.title}`}
                        >
                          <div className="flex gap-4 text-xl font-normal transition-colors duration-100 group-hover:text-pormade group-data-[selected=true]:text-pormade group-data-[state=open]:text-pormade">
                            {ContentIcon ? (
                              <ContentIcon className="size-7" />
                            ) : (
                              ''
                            )}
                            <span className="max-w-60 truncate">
                              {content.title}
                            </span>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent
                          className={
                            'ml-3 mt-1 flex flex-col items-start gap-5 border-l-[1px] border-pormade bg-gray/5 pl-3 pt-4 text-base transition-all duration-100'
                          }
                        >
                          {content.items &&
                            content.items.map((item, index) => (
                              <div
                                key={`${item.title}-${index}`}
                                title={`Acessar ${item.title?.toLowerCase()}`}
                              >
                                <SideBarGroupItem
                                  title={`${item.title}`}
                                  href={item.href ?? ''}
                                  icon={item.icon}
                                  onClick={() => setIsOpen((old) => !old)}
                                />
                              </div>
                            ))}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  );
                } else {
                  return (
                    <div
                      key={`${content.title}-${index}`}
                      title={`Acessar ${content.title?.toLowerCase()}`}
                    >
                      <SideBarGroupItem
                        large
                        title={content.title ?? ''}
                        href={content.href}
                        icon={content.icon}
                        onClick={() => setIsOpen((old) => !old)}
                        className="py-2"
                      />
                    </div>
                  );
                }
              })}
            </div>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </div>
  );
}
