import { cn } from '@/lib/utils';
import React from 'react';

type TitleProps = {
  children: React.ReactElement | string;
  className?: string;
  marginBottom?: boolean;
};

export default function Title({
  children,
  className,
  marginBottom = false,
}: TitleProps) {
  return (
    <div>
      <h1
        className={cn(
          'mt-5 text-2xl font-semibold sm:text-3xl md:text-4xl',
          marginBottom ? 'mb-1 sm:mb-2 md:mb-3' : '',
          className,
        )}
      >
        {children}
      </h1>
    </div>
  );
}
