const mobileKeywords = [
  'Android',
  'iPhone',
  'iPad',
  'iPod',
  'Opera Mini',
  'Opera Mobi',
  'Windows Phone',
  'Mobile',
];

export function checkIsMobileDevice() {
  return mobileKeywords.some((keyword) =>
    navigator.userAgent.includes(keyword),
  );
}
