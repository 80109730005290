import { useCounter } from '@/hooks/useCounter';
import { truncateNumber } from '@/utils';
import { useEffect, useState } from 'react';

const numbersAbbreviations = [
  {
    max: 999,
    abbreviation: '',
    function: (number: number) => number,
  },
  {
    max: 999999,
    abbreviation: 'K',
    function: (number: number) => truncateNumber(number / 1000, 1),
  },
  {
    max: 999999999,
    abbreviation: 'M',
    function: (number: number) => truncateNumber(number / 1000000, 1),
  },
  {
    max: 999999999999,
    abbreviation: 'B',
    function: (number: number) => truncateNumber(number / 1000000000, 1),
  },
];

type CounterProps = {
  isLoading?: boolean;
  value: number;
  description?: string;
};

export default function Counter({
  isLoading,
  value,
  description,
}: CounterProps) {
  const [abbreviatedTotalLeads, setAbbreviatedTotalLeads] = useState<string>();
  const [counterValue, setCounterValue] = useState<number>(0);

  const {
    counter: totalLeadsCounter,
    isFinished: isTotalLeadsCounterFinished,
  } = useCounter(counterValue, {
    delay: 500,
  });

  const getAbbreviatedTotalLeads = () => {
    const findedAbbreviationConfig = numbersAbbreviations.find(
      (option) => totalLeadsCounter <= option.max,
    );

    if (findedAbbreviationConfig) {
      const abbreviatedNumber =
        findedAbbreviationConfig.function(totalLeadsCounter);
      return abbreviatedNumber + ' ' + findedAbbreviationConfig.abbreviation;
    } else {
      return String(totalLeadsCounter);
    }
  };

  useEffect(() => {
    if (value) {
      setTimeout(() => {
        setCounterValue(value);
      }, 1000);
    }
  }, [value]);

  useEffect(() => {
    if (isTotalLeadsCounterFinished) {
      setTimeout(() => {
        setAbbreviatedTotalLeads(getAbbreviatedTotalLeads());
      }, 500);
    }
  }, [isTotalLeadsCounterFinished]);

  return (
    <div
      data-loading={isLoading}
      className="relative mb-10 flex aspect-square w-72 select-none flex-col items-center justify-center rounded-full bg-white/80 p-8 outline outline-2 outline-pormade data-[loading=true]:animate-pulse dark:bg-dark-gray/20 sm:w-96"
    >
      <p
        data-loaded={!!abbreviatedTotalLeads}
        data-description={!!description}
        className="text-nowrap text-7xl text-pormade data-[description=true]:data-[loaded=true]:mt-4 data-[description=true]:mt-7 data-[loaded=true]:animate-reveal-blink-two sm:text-8xl"
      >
        {abbreviatedTotalLeads
          ? abbreviatedTotalLeads
          : (totalLeadsCounter ?? 0)}
      </p>

      {description ? (
        <p
          data-visible={!!abbreviatedTotalLeads}
          className="base-text mt-1 font-medium text-muted-foreground transition-opacity delay-100 duration-500 data-[visible=false]:opacity-0 data-[visible=true]:opacity-100"
        >
          {description}
        </p>
      ) : (
        ''
      )}
    </div>
  );
}
