import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { tabsNames } from '@/configs/sheet.config';
import { cn } from '@/lib/utils';
import { useEffect, useReducer } from 'react';

type SheetReportProps = {
  sheetName?: string;
  reports: any[];
  className?: string;
  scrollAreaClassName?: string;
  title?: boolean;
  nameKey?: string;
  valueKey?: string;
};
export default function ReportList({
  sheetName,
  reports,
  className,
  scrollAreaClassName,
  title = false,
  nameKey = 'nome',
  valueKey = 'quantidade',
}: SheetReportProps) {
  const [isStarted, toggleIsStarted] = useReducer((state) => !state, false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      toggleIsStarted();
    }, 100);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {title ? (
        <h4 className="mb-4 px-2 text-center text-lg font-bold text-foreground">
          Relatório <span className="text-pormade">{sheetName}</span>
        </h4>
      ) : null}
      <div
        className={cn(
          'rounded-sm p-4 outline outline-1 outline-soft-gray',
          className,
        )}
      >
        <ScrollArea className={cn('h-40', scrollAreaClassName)} type="auto">
          <div
            className={cn(
              isStarted ? 'translate-x-0' : '-translate-x-[100%]',
              'p-5 transition-transform duration-700 ease-in-out',
            )}
          >
            {reports.map((report, index) => (
              <div key={`${report[nameKey]}-${index}`}>
                <div className="flex justify-between">
                  <b>{tabsNames[report[nameKey]] ?? report[nameKey]}:</b>

                  <p className="font-medium text-pormade-hover">
                    {report[valueKey]}
                  </p>
                </div>
                {index != reports.length - 1 ? (
                  <Separator className="my-2" />
                ) : null}
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    </>
  );
}
