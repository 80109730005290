import { cn } from '@/lib/utils';
import { Trash2 } from 'lucide-react';

type TrashButtonProps = {
  title?: string;
  onRemove?: () => void;
  className?: string;
};

export default function TrashButton({
  title = 'Excluir',
  className,
  onRemove,
}: TrashButtonProps) {
  return (
    <div
      title={title}
      className={cn(
        'cursor-pointer text-muted-foreground transition-all duration-200 hover:text-pormade-red',
        className,
      )}
      onClick={onRemove}
    >
      <Trash2 className=" " size={35} />
    </div>
  );
}
