import { TSelectItem } from '@/types';

type TPossibleHeaderConfig = {
  label: string;
  value: string;
  possibleValues: string[];
};

// prettier-ignore
export const possibleSheetHeadersValues : TPossibleHeaderConfig[]= [
  // INFORMAÇÕES PESSOAIS
  {label: 'Nome', value: 'nome', possibleValues: ['nome', 'name', 'nome completo', 'fullname', 'primeiro nome', 'sobrenome']},
  {label: 'Celular', value: 'celular', possibleValues: ['celular', 'cellphone', 'cellphone', 'mobile phone']},
  {label: 'Telefone', value: 'telefone', possibleValues: ['telefone', 'phone', 'fone', 'telefonema', 'linha direta']},
  {label: 'DDD', value: 'ddd', possibleValues: ['ddd', 'ddds']},
  {label: 'Email', value: 'email', possibleValues: ['email', 'e-mail', 'endereço de email', 'correio eletronico', 'caixa de entrada']},
  // PROFISSÃO / EMPRESA
  {label: 'Área de Atuação', value: 'area_atuacao', possibleValues: [ 'atuacao', 'ramo profissional', 'campo de trabalho', 'atividade', 'segmento profissional']},
  {label: 'Profissão', value: 'profissao', possibleValues: ['profissao', 'cargo', 'job', 'ocupacao', 'titulo profissional', 'especialidade']},
  {label: 'Empresa', value: 'empresa', possibleValues: ['empresa', 'organizacao', 'companhia', 'negocio', 'corporacao', 'instituicao', 'entidade', 'company']},
  {label: 'CPF / CNPJ', value: 'cnpj', possibleValues: ['cpf','cnpj']},
  {label: 'Razão Social', value: 'razao_social', possibleValues: ['razao social', 'razao_social', 'razao-social', 'nome fantasia', 'nome social', 'nome-social', 'nome_social', 'nome_fantasia', 'nome-fantasia', 'fantasia', 'nome da empresa', 'nome da organizacao', 'denominação social', 'razao comercial']},
  // ENDEREÇO
  {label: 'CEP', value: 'cep', possibleValues: ['cep', 'codigo postal', 'código postal', 'postal code', 'postalcode', 'zip code', 'zipcode']},
  {label: 'Estado', value: 'estado', possibleValues: ['estado','uf', 'state']},
  {label: 'Cidade', value: 'cidade', possibleValues: ['cidade', 'city', 'localidade', 'municipio']},
  {label: 'Endereço', value: 'endereco', possibleValues: ['endereco', 'endereço', 'address', 'rua', 'logradouro', 'avenida', 'localização', 'morada']},
  {label: 'Bairro', value: 'bairro', possibleValues: ['bairro', 'neighborhood', 'district', 'place']},
  // INFORMAÇÕES ADICIONAIS
  {label: 'Data', value: 'data', possibleValues: ['dia-mes-ano',  'dia_mes_ano', 'dia mes ano', 'data', 'date']},
  {label: 'Obs.', value: 'observacoes', possibleValues: ['obs', 'observação']},
  {label: 'Registrado por', value: 'usuario_registrador', possibleValues: ['consultor']},
]

export const possibleContactValues = ['telefone', 'celular', 'email'];
export const possibleJobValues = ['area_atuacao', 'profissao'];

export function findConfigByValue(value: string) {
  const slugText = value.makeSlug();
  return possibleSheetHeadersValues.find(
    (hConfig) =>
      hConfig.possibleValues.find((possibleValue) =>
        slugText.includes(possibleValue.makeSlug()),
      ) ?? false,
  );
}

export const allSheetHeadersValues = possibleSheetHeadersValues.map(
  (possible) => possible.value,
);

type Result = {
  [key: string]: number;
};

export const getInitialHeadersIDs = () => {
  const result: Result = {};
  possibleSheetHeadersValues.map((possible) => {
    result[possible.value] = -1;
  });
  return result;
};

export const headersComboboxData: TSelectItem[] = Object.keys(
  possibleSheetHeadersValues,
).map((key: any, index: number) => {
  const obj: any = {};
  if (key != 'possibleValues') {
    obj['label'] = possibleSheetHeadersValues[index].label;
    obj['value'] = possibleSheetHeadersValues[index].value;
    return obj;
  }
});
