import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import { ReactNode } from 'react';

type LoaderProps = {
  children?: string | ReactNode;
  className?: string;
  iconSize?: number;
  orientation?: 'horizontal' | 'vertical';
};

export default function Loading({
  className,
  iconSize = 60,
  children = 'Carregando',
  orientation = 'vertical',
}: LoaderProps) {
  return (
    <div
      className={cn(
        'flex select-none items-center justify-center gap-3 text-center text-soft-gray',
        orientation == 'vertical' ? 'flex-col' : '',
        className,
      )}
    >
      <Loader2 size={iconSize} className="animate-spin" />
      {children}
    </div>
  );
}
