import { TFileInfo } from '@/types';
import { FileSpreadsheet, X } from 'lucide-react';

type FileCardProps = {
  file: TFileInfo;
  removeFile: (file: TFileInfo) => void;
};
export default function FileCard({ file, removeFile }: FileCardProps) {
  return (
    <li className="relative flex gap-2 rounded-md bg-pormade px-5 py-3 font-semibold text-white">
      <FileSpreadsheet className="my-auto" />
      <div className="mx-2 w-full truncate">
        <p className="w-full truncate text-lg">{file?.name}</p>
        <p className="ml-1 text-xs text-white/70">
          {(file?.size / 1024).toFixed(2)} KB
        </p>
      </div>
      <button
        type="button"
        title="Remover arquivo"
        onClick={() => removeFile(file)}
      >
        <X
          size={28}
          className="cursor-pointer select-none rounded-full text-white hover:scale-95"
        />
      </button>
    </li>
  );
}
