import myToast from '@/components/Toast';
import { getSheetFilename } from '@/utils/sheet.utils';
import { AxiosError, isAxiosError } from 'axios';
import { api } from './api';
import { TOriginDetail } from './api.responses';

export function getAxiosErrorMessage(
  error: AxiosError<any>,
  alternativeErrorMessage: string,
) {
  if (error.response) {
    if (error.response.data.mensagem) {
      return String(error.response.data.mensagem);
    }

    if (typeof error.response.data == 'string') {
      if (
        !String(error.response.data).toLowerCase().includes('!doctype html')
      ) {
        if (error.response.data) {
          return String(error.response.data);
        }
      }
    }
  }
  return alternativeErrorMessage;
}

export async function downloadSheet(options: {
  origin: TOriginDetail;
  tab: string;
  includeAssumed: boolean;
}) {
  const { origin, tab, includeAssumed } = options;

  return api
    .get(
      'leads/gerar/planilha',

      {
        params: {
          crm_origem_id: origin.crm_origem_id,
          segmento_setor: tab,
          tratado: includeAssumed,
        },
        responseType: 'blob',
      },
    )
    .then((response) => {
      const downloadURL = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.setAttribute(
        'download',
        getSheetFilename(origin.crm_origem_nome, tab),
      );

      link.click();
    })
    .catch((error) => {
      if (isAxiosError(error) && error.response?.status == 403) {
        myToast.error('Você não tem permissão para *baixar planilhas.*');
        return;
      }

      myToast.error(
        getAxiosErrorMessage(
          error,
          'Não foi possível baixar a planilha. Tente novamente mais tarde.',
        ),
      );
    });
}
