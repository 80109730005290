import { cn } from '@/lib/utils';
import { api } from '@/services/api/api';
import { EventsResponse } from '@/services/api/api.responses';
import { TEvent } from '@/types';
import { getStartAndEndOfMonth } from '@/utils/date.utils';
import Autoplay from 'embla-carousel-autoplay';
import { useEffect, useRef, useState } from 'react';

import { Card, CardContent } from '@/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { PartyPopper } from 'lucide-react';

type EventsCarouselProps = {
  monthly?: boolean;
};
export function EventsCarousel({ monthly }: EventsCarouselProps) {
  const plugin = useRef(Autoplay({ delay: 3000, stopOnInteraction: true }));

  const [events, setEvents] = useState<TEvent[]>();

  useEffect(() => {
    const { startOfMonth, endOfMonth } = getStartAndEndOfMonth();

    setTimeout(() => {
      api
        .get<EventsResponse>('/buscas/eventos/aprovados', {
          params: {
            ordenar: 'data-asc',
            ...(monthly
              ? {
                  data_hora_inicio: startOfMonth.toISOString().split('T')[0],
                  data_hora_fim: endOfMonth.toISOString().split('T')[0],
                }
              : null),
          },
        })
        .then(({ data }) => setEvents(data.eventos))
        .catch(() => setEvents([]));
    }, 1000);
  }, []);

  return (
    <div
      className={cn(
        !events ? 'pointer-events-none opacity-0' : 'opacity-100',
        'w-full max-w-sm transition-opacity duration-1000 ease-in-out',
      )}
    >
      <div className="mb-3 flex items-center justify-center gap-4">
        <PartyPopper />
        {events && events.length > 0 ? (
          events.length == 1 ? (
            <p className="base-text font-medium">1 evento esse mês</p>
          ) : (
            <p className="base-text font-medium">
              {events.length} eventos esse mês
            </p>
          )
        ) : (
          <p className="base-text font-medium">Nenhum evento esse mês</p>
        )}
      </div>
      <Carousel
        plugins={[plugin.current]}
        onMouseEnter={plugin.current.stop}
        onMouseLeave={plugin.current.reset}
        className="mx-5 sm:mx-0"
      >
        <CarouselContent>
          {events && events.length > 0 ? (
            events.map((event, index) => (
              <CarouselItem key={`${event.nome}-${index}`}>
                <div className="p-1">
                  <Card className="border-foreground/40 bg-white/80 shadow-sm dark:bg-dark-gray/30">
                    <CardContent className="aspect-[1:2] flex w-full select-none flex-col flex-wrap items-center justify-center gap-1 p-6 py-10">
                      <p className="mx-auto text-center text-xl font-semibold">
                        {event.nome}
                      </p>
                      <p className="text-md mx-auto text-center font-semibold text-foreground/70">
                        {event.data_hora_inicio
                          ? new Date(event.data_hora_inicio).toLocaleDateString(
                              'pt-br',
                              {
                                dateStyle: 'medium',
                              },
                            )
                          : '--/--/----'}
                      </p>
                    </CardContent>
                  </Card>
                </div>
              </CarouselItem>
            ))
          ) : (
            <CarouselItem>
              <div className="p-1">
                <Card className="border-foreground/40 bg-white/80 shadow-sm dark:bg-dark-gray/30">
                  <CardContent className="aspect-[1:2] flex w-full select-none flex-col flex-wrap items-center justify-center gap-1 p-6 py-10">
                    <p className="mx-auto text-center text-xl font-medium">
                      Sem eventos esse mês
                    </p>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          )}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
}
