import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarShortcut,
  MenubarTrigger,
} from '@/components/ui/menubar';
import { Link, useLocation } from 'react-router-dom';

import { ChevronRight } from 'lucide-react';
import { TRouteItem } from '../../types';

type MenuBarProps = {
  contents: TRouteItem[];
  className?: string;
  isAuthenticated: boolean;
};

export function NavBar({ contents, className, isAuthenticated }: MenuBarProps) {
  const { pathname } = useLocation();

  function checkIfIsSelected(href?: string) {
    const locationPaths = pathname.split('/').filter((path) => path);

    if (!href) return false;
    if (pathname == href) return true;
    return locationPaths.includes(href.replace('/', ''));
  }

  return (
    <Menubar
      className={`${className} mx-10 hidden w-full justify-evenly border-none bg-transparent lg:flex`}
    >
      {isAuthenticated &&
        contents.map((content, index) => {
          const ContentIcon = content.icon;
          if (!content.href) {
            if (content.items?.length)
              return (
                <MenubarMenu key={`${content.title}-${index}`}>
                  <MenubarTrigger
                    title={`Ver ${content.title}`}
                    data-selected={
                      !!content.items.find((item) =>
                        checkIfIsSelected(item.href),
                      )
                    }
                    className="group cursor-pointer gap-3 text-white focus:bg-transparent focus:text-white data-[state=open]:bg-transparent data-[state=open]:text-white"
                  >
                    {ContentIcon ? (
                      <ContentIcon className="group-hover:text-pormade group-data-[selected=true]:text-pormade group-data-[state=open]:text-pormade" />
                    ) : (
                      ''
                    )}
                    <p className="border-pormade p-1 hover:border-b-[1px] group-hover:border-b-[1px] group-data-[selected=true]:border-b-[1px] group-data-[state=open]:border-b-[1px]">
                      {content.title}
                    </p>
                    <ChevronRight
                      className={`transition-all delay-100 duration-200 group-data-[state=open]:rotate-90`}
                      size={18}
                    />
                  </MenubarTrigger>
                  <MenubarContent
                    className="mt-1 hidden rounded-sm shadow-sm shadow-pormade/50 lg:block"
                    align="center"
                  >
                    {content.items &&
                      content.items.map((item, index) => {
                        const ItemIcon = item.icon;
                        return (
                          <Link
                            key={`${item.title}-${index}`}
                            to={`${item.href}`}
                          >
                            <MenubarItem
                              title={`Acessar ${item.title?.toLowerCase()}`}
                              className="group cursor-pointer gap-3 rounded-none border-l-2 border-pormade py-3 hover:bg-gray/20"
                            >
                              {ItemIcon ? (
                                <ItemIcon
                                  data-selected={checkIfIsSelected(item.href)}
                                  size={20}
                                  className="transition-colors duration-100 group-hover:text-pormade data-[selected=true]:text-pormade"
                                />
                              ) : (
                                ''
                              )}
                              {item.title}
                              {item.command ? (
                                <MenubarShortcut>
                                  {item.command}
                                </MenubarShortcut>
                              ) : (
                                ''
                              )}
                            </MenubarItem>
                          </Link>
                        );
                      })}
                  </MenubarContent>
                </MenubarMenu>
              );
          } else {
            return (
              <Link
                title={`Acessar ${content.title}`}
                to={content.href}
                key={`${content.title}-${index}`}
                className="group flex items-center gap-3 p-2 text-sm text-white"
                data-selected={checkIfIsSelected(content.href)}
              >
                {ContentIcon ? (
                  <ContentIcon className="transition-colors duration-200 group-hover:text-pormade group-data-[selected=true]:text-pormade" />
                ) : (
                  ''
                )}
                <p className="border-pormade p-1 hover:border-b-[1px] group-hover:border-b-[1px] group-data-[selected=true]:border-b-[1px]">
                  {content.title}
                </p>
              </Link>
            );
          }
        })}
    </Menubar>
  );
}
