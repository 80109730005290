import { cellsNames, headersNames } from '@/configs/sheet.config';
import { cn } from '@/lib/utils';
import { TSISInfos } from '@/types/leads.types';

import Frame from '@/components/Frame';
import { Separator } from '@/components/ui/separator';

type SiSCardProps = {
  sisInfos: TSISInfos;
};

const descriptionInfos = [
  'sis_pessoa_ativo',
  'sis_cliente_ativo',
  'sis_patrocinador_ativo',
  'sis_parceiro_ativo',
];

const hiddenSiSMoreInfos = [...descriptionInfos, 'sis_id', 'sis_nome'];

const infoColors: { [key: string]: string } = {
  true: 'pormade',
  false: 'pormade-red',
};

const couponIDColors: { [key: string]: string } = {
  ARQ: 'pormade',
  INS: 'pormade-blue',
};

export default function SiSCard({ sisInfos }: SiSCardProps) {
  function getCouponID(coupon: string) {
    return coupon.slice(0, 3);
  }

  return (
    <Frame
      moreContent={
        <div className="custom-scrollbar max-h-56 overflow-y-auto">
          {Object.entries(sisInfos).map(([key, value], index) =>
            !hiddenSiSMoreInfos.includes(key) && value != null ? (
              <div key={`${sisInfos.sis_id}-${index}`}>
                <p>
                  {headersNames[String(key)] ?? key}:
                  <b className="font-semibold"> {value}</b>
                </p>

                <Separator className="my-2" />
              </div>
            ) : (
              ''
            ),
          )}
        </div>
      }
    >
      <div className="grid gap-2">
        <div className="medium-text flex flex-wrap justify-between gap-2 font-semibold">
          <p>{sisInfos.sis_nome}</p>
          <p>
            {sisInfos.sis_numero_cupom ? (
              <span className="text-muted-foreground">
                {' '}
                <b
                  className={cn(
                    `text-${
                      couponIDColors[
                        getCouponID(String(sisInfos.sis_numero_cupom))
                      ]
                    }`,
                    'font-extrabold',
                  )}
                >
                  #{getCouponID(sisInfos.sis_numero_cupom)}
                </b>
                {sisInfos.sis_numero_cupom.slice(3)}
              </span>
            ) : (
              ''
            )}
          </p>
        </div>
        <div>
          {descriptionInfos.map((descriptionInfo, index) => (
            <p key={`${descriptionInfo}-${index}`}>
              {headersNames[descriptionInfo]}:{' '}
              <b
                className={cn(
                  infoColors[
                    String(sisInfos[descriptionInfo as keyof TSISInfos]) ?? ''
                  ] &&
                    `text-${
                      infoColors[
                        String(sisInfos[descriptionInfo as keyof TSISInfos]) ??
                          ''
                      ]
                    } `,
                  'w-fit p-1 font-semibold',
                )}
              >
                {cellsNames[
                  String(sisInfos[descriptionInfo as keyof TSISInfos])
                ] ?? '-'}
              </b>
            </p>
          ))}
        </div>
      </div>
    </Frame>
  );
}
