import { CHART_COLORS } from '@/colors';
import { TChartType } from '@/types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from './ui/chart';

export type ChartProps = {
  data: any[];
  type?: TChartType;
  valueKey: string;
  nameKey: string;
};

export default function Chart({
  data,
  type = 'Bar',
  valueKey,
  nameKey,
}: ChartProps) {
  if (type == 'Pie') {
    data = data.filter((item) => item[valueKey] > 0);
  }

  const DefaultTooptip = (
    <ChartTooltip
      animationDuration={200}
      content={
        <ChartTooltipContent
          className="border border-muted-foreground py-2"
          // color="var(--pormade-color)"
          hideIndicator
        />
      }
    />
  );
  return (
    <ResponsiveContainer className="size-full">
      {type == 'Bar' ? (
        <ChartContainer config={{}}>
          <BarChart accessibilityLayer data={data}>
            <YAxis dataKey={valueKey} fillRule="nonzero" tickMargin={10} />
            <XAxis axisLine dataKey={nameKey} tickMargin={4} />
            {DefaultTooptip}
            <CartesianGrid
              vertical={false}
              className="stroke-muted-foreground/20"
              stroke="var(--foreground)"
            />
            <Bar
              dataKey={valueKey}
              name={'Total'}
              imageRendering={'optmizeSpeed'}
              radius={4}
            >
              {data.map((_, index) => (
                <Cell
                  key={index}
                  fill={CHART_COLORS[index % CHART_COLORS.length]}
                />
              ))}
            </Bar>
          </BarChart>
        </ChartContainer>
      ) : (
        <ChartContainer config={{}}>
          <PieChart className="size-full">
            {DefaultTooptip}
            <Pie
              label
              data={data}
              dataKey={valueKey}
              nameKey={nameKey}
              animationBegin={10}
              animationDuration={800}
              imageRendering={'optmizeSpeed'}
            >
              {data.map((_, index) => (
                <Cell
                  key={index}
                  fill={CHART_COLORS[index % CHART_COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ChartContainer>
      )}
    </ResponsiveContainer>
  );
}
