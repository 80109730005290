import { PrivateRoutesKeys, useNavigation } from '@/contexts/navigationContext';
import { ReactNode, useEffect } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

interface ProtectedRouteProps {
  element: ReactNode;
  route: PrivateRoutesKeys;
  goBackTo: To;
}

export default function NavigationRoute({
  element,
  route,
  goBackTo,
}: ProtectedRouteProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const { routesStates } = useNavigation();

  useEffect(() => {
    if (!routesStates[route]) {
      navigate(goBackTo);
    }
  }, [routesStates]);

  useEffect(() => {
    if (!routesStates[route]) {
      navigate(goBackTo);
    }
  }, [location]);

  if (!routesStates[route]) return <></>;
  return <>{element}</>;
}
