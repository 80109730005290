import { api } from '@/services/api/api';
import { TSheetsConfigs } from '@/services/api/api.dto';
import { TSheetReportItem } from '@/services/api/api.responses';
import { socket } from '@/services/socket';
import { Check, FileX2 } from 'lucide-react';
import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import {
  ToastLoaderComponent,
  ToastLoaderComponentProps,
} from './ToastLoaderComponent';
import { cn } from '@/lib/utils';
import { defaultToastClassName } from '@/App';

export type TSocketStatusResponse = { mensagem: string; porcentagem: number };
type HandleStatusProps = TSocketStatusResponse & {
  crmOriginId?: number;
};

export type TProcessingStatus = {
  message: string;
  percent: number;
  finished: boolean;
  ok?: boolean;
};

type ProcessingSheetProps = {
  children: ReactNode;
};

type ProcessSheetProps = {
  files: File[];
  sheetConfigs: TSheetsConfigs;
};

type ProcessingSheetContextProps = {
  processSheet: (props: ProcessSheetProps) => Promise<unknown>;
};

type ProcessingStatusProps = ToastLoaderComponentProps & {
  onClick?: () => void;
  crmOriginId?: number;
  closeButton?: boolean;
  renderClassName?: string;
};

const ProcessingSheetContext = createContext<ProcessingSheetContextProps>(
  {} as ProcessingSheetContextProps,
);

export const useProcessigSheet = () => useContext(ProcessingSheetContext);

export function ProcessingSheetProvider({ children }: ProcessingSheetProps) {
  const navigate = useNavigate();

  const processingToastId = useRef<Id>();

  function handleStatus(data: HandleStatusProps) {
    setProcessingStatus({
      description: data.mensagem,
      percentage: data.porcentagem,
      crmOriginId: data.crmOriginId,
    });

    return () => {
      socket.off('status_processamento_planilha');
    };
  }

  function preventPageReload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  async function processSheet({ files, sheetConfigs }: ProcessSheetProps) {
    const crmOriginId = sheetConfigs.planilha_crm_origem_id;

    setProcessingStatus({
      description: 'Iniciando processamento',
      percentage: 0,
      crmOriginId: crmOriginId,
    });

    socket.on('status_processamento_planilha', (data) =>
      handleStatus({ ...data, crmOriginId }),
    );

    const formData = new FormData();
    files?.forEach((file) => formData.append('file', file));
    formData.append(
      'config',
      JSON.stringify({
        ...sheetConfigs,
        usuario_registrador: null,
      }),
    );

    window.addEventListener('beforeunload', preventPageReload);

    const toastId = processingToastId.current;

    await api
      .post<TSheetReportItem[]>('leads/planilhas/cadastrar', formData)
      .then(() => {
        socket.off('status_processamento_planilha');

        setProcessingStatus({
          crmOriginId,
          title: 'Processo concluído!',
          description:
            'Planilha processada com sucesso. Clique aqui para acessá-la.',
          percentage: 100,
          icon: <Check className="text-pormade" />,
          className: 'animate-success-toast',
          renderClassName: 'cursor-pointer',
          onClick: () => {
            navigate('/leads', { replace: true });

            setTimeout(() => {
              navigate(`/leads/${crmOriginId}`);
            }, 10);

            toast.dismiss(toastId);
          },
          closeButton: true,
        });
      })
      .catch(() => {
        setProcessingStatus({
          title: 'Erro ao processar!',
          description:
            files.length > 1
              ? 'Não foi possível processar as planilhas. Tente novamente mais tarde!'
              : 'Não foi possível processar a planilha. Tente novamente mais tarde!',
          icon: <FileX2 className="text-pormade-red" />,
          className: 'animate-shake',
          closeButton: true,
        });
      })
      .finally(() => {
        window.removeEventListener('beforeunload', preventPageReload);
        socket.off('status_processamento_planilha');
        processingToastId.current = undefined;
      });
  }

  function setProcessingStatus({
    onClick,
    closeButton = false,
    className,
    renderClassName,
    ...props
  }: ProcessingStatusProps) {
    if (processingToastId.current) {
      toast.update(processingToastId.current, {
        render: <ToastLoaderComponent className={renderClassName} {...props} />,
        onClick: onClick,
        closeButton,
        className: cn(defaultToastClassName, className),
      });
    } else {
      processingToastId.current = toast(
        <ToastLoaderComponent className={renderClassName} {...props} />,
        {
          autoClose: false,
          position: 'bottom-left',
          closeButton,
          className: cn(defaultToastClassName, className),
        },
      );
    }
  }

  useEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', preventPageReload);
      socket.off('status_processamento_planilha');
      processingToastId.current = undefined;
    };
  }, []);

  return (
    <ProcessingSheetContext.Provider value={{ processSheet }}>
      {children}
    </ProcessingSheetContext.Provider>
  );
}
