import { cn } from '@/lib/utils';
import { initialDarkModeEnabled } from '@/utils/theme.utils';
import { Moon, Sun } from 'lucide-react';
import { useReducer } from 'react';

type ToggleThemeButtonProps = {
  className?: string;
  size?: number;
};

export default function ToggleThemeButton({
  className,
  size = 24,
}: ToggleThemeButtonProps) {
  const [darkMode, toggleDarkMode] = useReducer((state) => {
    window.localStorage.setItem('darkMode', String(!state));
    document.body.classList.toggle('dark');
    return !state;
  }, initialDarkModeEnabled);

  return (
    <button
      className={cn('text-white', className)}
      type="button"
      onClick={toggleDarkMode}
    >
      {darkMode ? <Moon size={size} /> : <Sun size={size} />}
    </button>
  );
}
