import * as React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Button } from './ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './ui/command';
import { CheckIcon, ChevronRight, X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { TSelectItem } from '@/types';

type ComboboxProps = {
  title?: string | undefined;
  searchTitle?: string;
  emptyTitle?: string;
  data: TSelectItem[];
  onChange: ({ label, value }: TSelectItem) => void;
  value?: string | number | undefined;
  className?: string;
  search?: boolean;
};
export function Combobox({
  title = 'Selecione',
  searchTitle = 'Busque',
  emptyTitle = 'Não encontrado',
  value,
  data,
  onChange,
  className,
  search = true,
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState('');
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className={cn('group relative', className)}>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="group"
        >
          {value ? data.find((item) => item.value === value)?.label : title}
          <ChevronRight className="ml-2 h-4 w-4 shrink-0 opacity-70 transition-transform duration-100 group-data-[state=open]:rotate-90" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="border border-muted-foreground bg-background shadow-lg shadow-black/50">
        <Command>
          {search ? (
            <>
              <CommandInput placeholder={searchTitle} className="h-9" />
              <CommandEmpty className="p-4 text-sm">{emptyTitle}</CommandEmpty>
            </>
          ) : (
            ''
          )}

          <CommandList className="custom-scrollbar">
            <CommandGroup className="bg-background">
              {data.map((item, index) => (
                <CommandItem
                  key={`${item.label}-${index}`}
                  data-border={item.label.toLowerCase().includes('selecione')}
                  className="gap-2 border-muted-foreground bg-background py-2 text-foreground transition-colors data-[border=true]:mb-2 data-[border=true]:border data-[disabled=true]:opacity-50"
                  disabled={false}
                  onSelect={() => {
                    onChange({ label: item.label, value: item.value });
                    setOpen(false);
                  }}
                  onClick={() => {
                    onChange({ label: item.label, value: item.value });
                    setOpen(false);
                  }}
                >
                  {item.label.toLowerCase().includes('selecione') ? (
                    <>
                      <X className="text-pormade-red" /> Remover seleção
                    </>
                  ) : (
                    item.label
                  )}
                  <CheckIcon
                    className={cn(
                      'ml-auto h-4 w-4',
                      value === item.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
