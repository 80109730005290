import { useState } from 'react';

type UseLoadingReturn<T> = [
  boolean,
  (asyncFunction: () => Promise<T>) => Promise<void>,
];

function useLoading<T>(initialState: boolean = false): UseLoadingReturn<T> {
  const [isLoading, setIsLoading] = useState<boolean>(initialState);

  const executeWithLoading = async (asyncFunction: () => Promise<T>) => {
    setIsLoading(true);
    try {
      await asyncFunction();
    } finally {
      setIsLoading(false);
    }
  };

  return [isLoading, executeWithLoading];
}

export default useLoading;
