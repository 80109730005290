export const DEFAULT_COLORS = {
  pormade: '#00A03C',
  'pormade-hover': '#008B34',
  'pormade-warn': '#DC9E00',
  'pormade-warn-hover': '#C58E00',
  'pormade-blue': '#18B0D1',
  'pormade-red': '#DD1F1F',
  'pormade-red-hover': '#AE1818',
  'soft-gray': '#CDCDCD',
  'dark-gray': '#4D4D4D',
  'dark-black': '#030404',
  gray: '#868686',
  black: '#121212',
};

export const CHART_COLORS = [
  '#00A03C',
  '#00632A',
  '#FFCF5C',
  '#FF8A33',
  '#E8565D',
  '#FD003A',
  '#FFA07A',
  '#771090',
  '#B18CD9',
  '#18B0D1',
  '#003A70',
  '#B3B3B3',
];
