import { useEffect, useRef, useState } from 'react';

interface LazyLoadComponentProps {
  children: React.ReactNode;
  observerMargin?: string;
}

function LazyLoadComponent({
  children,
  observerMargin = '300px',
}: LazyLoadComponentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [childrenHeight, setChildrenHeight] = useState<number>();

  const lazyComponentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: observerMargin,
        threshold: 0,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const handleChildrenRef = (node: HTMLDivElement | null) => {
    if (node) setChildrenHeight(node.offsetHeight);
  };

  return (
    <div ref={ref} className="relative w-full">
      {isVisible ? (
        <div ref={handleChildrenRef}>{children}</div>
      ) : (
        <div
          ref={lazyComponentRef}
          className="w-full rounded-md bg-muted outline outline-1 outline-soft-gray/70"
          style={{
            height: childrenHeight ?? '300px',
          }}
        />
      )}
    </div>
  );
}

export default LazyLoadComponent;
