import { cn } from '@/lib/utils';
import { Copy, CopyCheck } from 'lucide-react';
import { useReducer } from 'react';

type CopyButtonProps = {
  text: string;
  description?: boolean;
  className?: string;
  onClick?: () => void;
};

export default function CopyButton({
  text,
  description,
  className,
  onClick,
}: CopyButtonProps) {
  const [wasCopied, toggleWasCopied] = useReducer((state) => !state, false);

  function handleCopy() {
    if (onClick) onClick();
    navigator.clipboard.writeText(text);
    toggleWasCopied();
    setTimeout(() => {
      toggleWasCopied();
    }, 3000);
  }
  return (
    <div className={cn('flex items-center justify-center', className)}>
      {wasCopied ? (
        <div
          title="Copiado com sucesso"
          className="flex animate-pulse items-center gap-2 text-pormade"
        >
          <CopyCheck />
          {description ? <p>Copiado!</p> : ''}
        </div>
      ) : (
        <button
          title="Copiar"
          onClick={handleCopy}
          className="flex items-center gap-2"
        >
          <Copy />
          {description ? <p>Copiar</p> : ''}
        </button>
      )}
    </div>
  );
}
