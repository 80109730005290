import { io } from 'socket.io-client';

export const socket = io(
  import.meta.env.DEV
    ? import.meta.env.VITE_API_BASE_URL_DEV
    : import.meta.env.VITE_API_BASE_URL_PROD.replace('/api', ''),
  {
    autoConnect: false,
    withCredentials: true,
    auth: { token: '' },
    path: import.meta.env.DEV ? '/socket.io' : '/api/socket.io',
    port: 2154,
    transports: ['polling'],
  },
);
