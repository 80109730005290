import Highlighter from 'react-highlight-words';
type DefaultHighlighterProps = {
  searchWords?: string;
  children?: string;
  className?: string;
  highlightClassName?: string;
};

export default function DefaultHighlighter({
  searchWords,
  children,
  className,
  highlightClassName,
}: DefaultHighlighterProps) {
  return (
    <Highlighter
      highlightClassName={highlightClassName}
      className={className}
      searchWords={searchWords ? [searchWords] : ['']}
      textToHighlight={children ?? ''}
      autoEscape
    />
  );
}
