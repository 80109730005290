import { TChartType } from '@/types';
import { BarChart, List, PieChart } from 'lucide-react';
import { ReactNode, useState } from 'react';
import Chart, { ChartProps } from './Chart';
import ReportList from './ReportList';
import { Button } from './ui/button';
import { cn } from '@/lib/utils';

type TChartTypeConfig = {
  type: TChartType;
  name: string;
  icon: ReactNode;
};

const defaultChartsTypesConfigs: TChartTypeConfig[] = [
  { type: 'Bar', name: 'Barra', icon: <BarChart /> },
  { type: 'Pie', name: 'Pizza', icon: <PieChart /> },
  { type: 'List', name: 'Lista', icon: <List /> },
];

type ChartsProps = ChartProps & {
  chartsTypesConfigs?: TChartTypeConfig[];
  children?: ReactNode;
};

function Charts({
  data,
  nameKey,
  valueKey,
  children,
  chartsTypesConfigs = defaultChartsTypesConfigs,
}: ChartsProps) {
  const [selectedChartType, setSelectedChartType] = useState<TChartTypeConfig>(
    chartsTypesConfigs[0],
  );

  return (
    <div className="flex size-full flex-col rounded-md p-4 outline outline-1 outline-soft-gray md:p-8">
      {children}
      <div className="mb-6 flex flex-wrap justify-center gap-4">
        {chartsTypesConfigs.map((chartType) => (
          <Button
            key={chartType.name}
            title={`Alterar gráfico para o tipo ${chartType.name}`}
            variant={'outline'}
            onClick={() => setSelectedChartType(chartType)}
            className={cn(
              selectedChartType.type == chartType.type
                ? 'border-none bg-pormade/80 text-white ring-1 ring-foreground ring-offset-2 hover:bg-pormade-hover/80 hover:text-white'
                : '',
              'px-4',
            )}
          >
            <div className="flex gap-3 self-center">{chartType.icon}</div>
          </Button>
        ))}
      </div>
      <div className="m-auto flex h-80 w-full">
        {selectedChartType.type == 'List' ? (
          <ReportList
            scrollAreaClassName="h-64"
            className="mx-auto w-full max-w-3xl"
            reports={data}
            nameKey={nameKey}
            valueKey={valueKey}
          />
        ) : (
          <Chart
            type={selectedChartType.type}
            nameKey={nameKey}
            valueKey={valueKey}
            data={data}
          />
        )}
      </div>
    </div>
  );
}
type ChartsTitleProps = {
  children?: ReactNode | string;
  className?: string;
};
function ChartsTitle({ children, className }: ChartsTitleProps) {
  return (
    <h2
      className={cn(
        'mx-auto flex items-center gap-4 text-xl font-medium sm:text-2xl',
        className,
      )}
    >
      {children}
    </h2>
  );
}

Charts.Title = ChartsTitle;

export default Charts;
