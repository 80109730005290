import { cn } from '@/lib/utils';
import { ReactNode, useEffect, useRef } from 'react';

type VirtualScrollProps = {
  children: ReactNode;
  canLoadMore: boolean;
  loadMore: () => void;
  className?: string;
};

export default function VirtualScroll({
  children,
  canLoadMore,
  loadMore,
  className,
}: VirtualScrollProps) {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interceptor = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting) && canLoadMore) {
        loadMore();
      }
    });

    if (loadMoreRef.current) interceptor.observe(loadMoreRef.current);

    return () => interceptor.disconnect();
  }, [children]);

  return (
    <main className={cn('w-full', className)}>
      {children}

      <footer
        data-hidden={!canLoadMore}
        className="w-full data-[hidden=true]:hidden"
      >
        <span
          ref={loadMoreRef}
          className="absolute bottom-0 left-0 -z-10 h-[75rem] w-full"
        />
      </footer>
    </main>
  );
}
