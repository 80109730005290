import { ReactNode, createContext, useContext, useState } from 'react';

export type PrivateRoutes = {
  sheetProcessing: boolean;
  configSheet: boolean;
};

export type PrivateRoutesKeys = keyof PrivateRoutes;

const NavigationContext = createContext<
  | {
      routesStates: PrivateRoutes;
      setRouteAccess: (route: keyof PrivateRoutes, state: boolean) => void;
    }
  | undefined
>(undefined);

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const [routesStates, setRoutesStates] = useState<PrivateRoutes>({
    sheetProcessing: false,
    configSheet: false,
  });

  function setRouteAccess(route: keyof PrivateRoutes, state: boolean) {
    setRoutesStates((old) => ({ ...old, [route]: state }));
  }

  return (
    <NavigationContext.Provider
      value={{
        routesStates,
        setRouteAccess,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error(
      'useNavigation deve ser usado dentro de um NavigationProvider',
    );
  }
  return context;
};
