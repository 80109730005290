import { cn } from '@/lib/utils';
import { Headset, LucideIcon } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Background from './Background';
import { Button } from './ui/button';

type ErrorPageProps = {
  title?: string;
  titleSize?: keyof typeof titleSizes;
  subtitle?: string;
  description?: string;
  variant?: 'error' | 'warn';
  navigateButton?: boolean;
  buttonNavigateTo?: string | number;
  navigateButtonText?: string;
  icon?: LucideIcon;
  enableSupportButton?: boolean;
};

const titleSizes = {
  sm: 'subtitle',
  base: 'title',
  lg: 'text-5xl sm:text-6xl lg:text-7xl',
  veryLarge: 'text-7xl md:text-8xl lg:text-9xl',
};

export default function ErrorPage({
  title = 'Problemas técnicos',
  titleSize = 'base',
  subtitle = 'Nossa equipe já está trabalhando para resolver',
  description,
  variant = 'error',
  navigateButton = true,
  navigateButtonText = 'Tentar novamente',
  buttonNavigateTo = 0,
  icon: Icon,
  enableSupportButton,
}: ErrorPageProps) {
  const navigate = useNavigate();
  const colorVariants = {
    error: 'text-pormade-red',
    warn: 'text-pormade-warn',
  };

  return (
    <div className="center-center fixed size-full">
      <Background variant={variant} />
      <section className="center-center flex flex-col p-10 text-center backdrop-blur-sm">
        {Icon ? (
          <Icon
            className={cn(colorVariants[variant], 'mb-5 size-32 animate-pulse')}
          />
        ) : null}
        <div>
          <h1
            className={cn(
              colorVariants[variant],
              titleSizes[titleSize],
              'font-medium',
            )}
          >
            {title}
          </h1>
          <h2 className="medium-text mt-3 text-muted-foreground">
            <b>{subtitle}</b>
          </h2>
          {description ? <p className="mt-5">{description}</p> : ''}
          <div className="mx-auto mt-10 grid max-w-lg justify-center gap-5">
            {navigateButton ? (
              <Button
                onClick={() => navigate(buttonNavigateTo as never)}
                variant={'outline'}
              >
                {navigateButtonText}
              </Button>
            ) : null}
            {enableSupportButton ? (
              <Link to={import.meta.env.VITE_SUPPORT_URL} target="_blank">
                <Button variant="ghost" className="flex w-full gap-2 px-2 py-1">
                  <Headset /> Suporte
                </Button>
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
